import React from 'react';
import { Form, Button, Row, Col, Card, Container, Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import abvLogo from './sbv_logo.png';

import LoginPageImage from './loginPageImage.jpg';
import IIC from './rankingImages/IIC.png';
import ISO from './rankingImages/ISO.png';
import NAAC_2021 from './rankingImages/NAAC 2021.png';
import NABH_2022 from './rankingImages/NABH 2022.png';
import NABL_MGMCRI_2025 from './rankingImages/NABL MGMCRI 2025.png';
import NABL_SSSMCRI_2020 from './rankingImages/NABL SSSMCRI 2020.png';
import NIRF_MGMCRI_2023 from './rankingImages/NIRF MGMCRI 2023.png';
import NIRF_SBV_2023 from './rankingImages/NIRF SBV 2023.png';
import Outlook_2023 from './rankingImages/Outlook 2023.png';
import QCI from './rankingImages/QCI.png';
import QS_I_GAUGE_2022 from './rankingImages/QS I-GAUGE 2022.png';
import SIRO from './rankingImages/SIRO.png';

import Rigester from './Register';
import ForgotPassword from './Forgotpassword';

import { login, getActiveInstitutesandCourses } from './services';

function Login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [loginShow, setLoginShow] = React.useState(false);
  const [registerShow, setRegisterShow] = React.useState(true);
  const [forgotShow, setForgotShow] = React.useState(false);
  const [pondiInstitutes, setPondiInstitutes] = React.useState([]);
  const [chennaiInstitutes, setChennaiInstitutes] = React.useState([]);
  const [karaikalInstitutes, setKaraikalInstitutes] = React.useState([]);

  const highlights = [
    { value: "15k+", label: "Alumni" },
    { value: "169+", label: "Multidisciplinary UG, PG, PhD Programs" },
    { value: "650+", label: "Internationally Acclaimed Faculty" },
    { value: "2,500", label: "Bedded Teaching Hospital" },
    { value: "A++", label: "Highest Grade in NAAC" },
    { value: "35 lakh+", label: "Patients treated" }
  ];

  React.useEffect(() => {
    getAllInstitutes();
  }, []);

  const submit = async (data) => {
    try {
      const user = await login(data);
      if (user) {
        localStorage.setItem("username", user.email);
        localStorage.setItem('role', user.role);
        localStorage.setItem('domain', user.domain);
        navigate("/sidebar");
      }
    } catch (err) {
      console.log(err);
      swal({
        title: err.response.data,
        icon: 'error'
      });
    }
  };

  const changeState = () => {
    setLoginShow(false);
    setRegisterShow(true);
    setForgotShow(false);
  };

  const changeState1 = () => {
    setLoginShow(false);
    setRegisterShow(false);
    setForgotShow(true);
  };

  const changeState2 = () => {
    setLoginShow(true);
    setRegisterShow(false);
    setForgotShow(false);
  };

  const getAllInstitutes = async () => {
    try {
      const result = await getActiveInstitutesandCourses();
      if (result.length > 0) {
        const categorizedInstitutes = result.reduce(
          (acc, object) => {
            if (object.campus === "Pondicherry Campus") {
              acc.Pondicherry.push(object);
            } else if (object.campus === "Chennai Campus") {
              acc.Chennai.push(object);
            } else if (object.campus === "Karaikal Campus") {
              acc.Karaikal.push(object);
            }
            return acc;
          },
          { Pondicherry: [], Chennai: [], Karaikal: [] }
        );

        setPondiInstitutes(categorizedInstitutes.Pondicherry);
        setChennaiInstitutes(categorizedInstitutes.Chennai);
        setKaraikalInstitutes(categorizedInstitutes.Karaikal);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openBrouchure = (link) => {
    if (!link) return;
    window.open(link, "_blank");
  };

  return (
    <>
      <Container fluid className="p-0 m-0"
        style={{
          backgroundColor: "#2e86c1", // Added background color to replace the image
          height: "100vh", // Center vertically
        }}>
        <Row className="justify-content-center align-items-center h-100">
          {/* Login/Register Section */}
          <Col xs={12} sm={6} md={4}
            style={{
              padding: '20px',
              color: '#080808',
            }}
            className="d-flex flex-column justify-content-center">
            {loginShow && (
              <div className="w-100" style={{ backgroundColor: 'rgba(255,255,255,0.9)', borderRadius: '10px', padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h3 className="text-center">Login</h3>
                  <Image src={abvLogo} alt="Logo" style={{ width: '70px', height: '90px' }} />
                </div>
                <Form onSubmit={handleSubmit(submit)}>
                  <Form.Group className='mt-3' controlId="formBasicEmail">
                    <Form.Control {...register('email', { required: true })} type="email" placeholder="Email Id" />
                  </Form.Group>

                  <Form.Group className='mt-3' controlId="formBasicPassword">
                    <Form.Control {...register('password', { required: true, minLength: 6 })} type="password" placeholder="Password" />
                  </Form.Group>

                  <Button
                    variant="primary"
                    size='sm'
                    type="submit"
                    className="mt-3 w-100"
                  >
                    Login
                  </Button>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Link className='custom-link' to="" onClick={() => changeState1()}>Forgot Password?</Link>
                    <Link to="/adminlogin" className="custom-link">Admin</Link>
                  </div>
                </Form>

                <div className="d-flex align-items-center pt-2 justify-content-center">
                  <span style={{ marginRight: '10px' }}>New User?</span>
                  <Link to="" onClick={() => changeState()} className='register_login'>Register</Link>
                </div>
              </div>
            )}
            {registerShow && <Rigester stateToggle={changeState2} />}
            {forgotShow && <ForgotPassword stateToggle={changeState} />}
          </Col>
        </Row>
      </Container>





      {/* <Container className="mt-5">
        <h2 className="text-center mb-4 font-weight-bold">Highlights</h2>
        <Row className="justify-content-center">
          {highlights.map((item, index) => (
            <Col key={index} md={4} className="mb-4">
              <Card className="text-center shadow-sm">
                <Card.Body>
                  <Card.Title className="text-danger font-weight-bold display-6">{item.value}</Card.Title>
                  <Card.Text className="text-muted">{item.label}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container> */}

      {/* <Container className='mt-5'>
        <h2 style={{ color: '#000000' }} className="text-center mb-3 font-weight-bold">Ranking</h2>
        <Carousel
          className='mt-5'
          controls={false}
          indicators={false}
        >
          <Carousel.Item>
            <Row className='g-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NAAC_2021}
                  alt="First slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NABH_2022}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NABL_MGMCRI_2025}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <Row className='g-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NABL_SSSMCRI_2020}
                  alt="First slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NIRF_MGMCRI_2023}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={NIRF_SBV_2023}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <Row className='g-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={Outlook_2023}
                  alt="First slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={QCI}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={QS_I_GAUGE_2022}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <Row className='g-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={SIRO}
                  alt="First slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={IIC}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
              <Col
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                sm={12} md={4} lg={4} xl={4}>
                <Image
                  className="d-block"
                  src={ISO}
                  alt="Second slide"
                  style={{ width: '320px', height: '130px' }}
                />
              </Col>
            </Row>
          </Carousel.Item>

        </Carousel>
      </Container> */}

      {/* <Container>
        <h2 style={{ color: '#000000' }} className="text-center font-weight-bold mt-5">About SBV</h2>
        <p className='mt-5' style={{ textAlign: 'justify', color: '#230342' }}>Sri Balaji Vidyapeeth (SBV), a Deemed-to-be-University, was established in 2008, excelling in academics, research, patient care, and outreach services.
          It has campuses in Pondicherry and Chennai, with a new campus planned in Karaikal.
          SBV comprises medical, dental, nursing, pharmacy, physiotherapy, and allied health sciences institutions.
          It holds an A++ Grade from NAAC and ranks among India’s top 100 universities per NIRF (2016-2024).
          SBV's hospitals and laboratories are NABH and NABL accredited, with an ICMR-approved RT-PCR lab.
          Recognized as a Scientific and Industrial Research Organisation (SIRO), it fosters multidisciplinary education and research.
          SBV promotes quality biomedical research with a dedicated Deanery for Research. Its alumni excel globally, supported by a strong Alumni Association.
          The university is committed to innovation, quality education, and impactful healthcare services.</p>
      </Container> */}

      {/* <Container
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className='mt-5'
      >
        <iframe width="57%" height="360"
          src="https://www.youtube.com/embed/NtNlTTxckuc?si=j42-5USVHVKH1lNV&rel=0&fs=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </Container> */}

      {/*   <Container
        className="mt-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <h6>ADMISSION OPEN</h6>
        <h2>Programme Offered</h2>
      </Container> */}

      {/* <Container className="pb-5 mt-5">
        <Row>
          <Col sm={12} md={6} lg={6} xl={6}>
            <h4 style={{
              color: '#000000',
              paddingBottom: '25px'
            }}>Pondicherry Campus</h4>
            {
              pondiInstitutes.map((object, index) => (
                <Accordion key={index} className="mb-2 custom-accordion">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>
                      {object.institute}
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        object.course.map((obj, index) => (
                          <div
                            onClick={() => { openBrouchure(object.brouchureLink) }}
                            key={index}
                            className="custom-box"
                          >
                            {obj.courseName}
                          </div>
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            }
          </Col>
          <Col sm={12} md={6} lg={6} xl={6}>
            <h4 style={{
              color: '#000000',
              paddingBottom: '25px'
            }}>Chennai Campus</h4>
            {
              chennaiInstitutes.map((object, index) => (
                <Accordion key={index} className="mb-2 custom-accordion">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{object.institute}</Accordion.Header>
                    <Accordion.Body>
                      {
                        object.course.map((obj, index) => (
                          <div
                            onClick={() => { openBrouchure(object.brouchureLink) }}
                            key={index}
                            className="custom-box">
                            {obj.courseName}
                          </div>
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            }
            <h4 style={{
              color: '#000000',
              paddingBottom: '25px',
              paddingTop: '25px'
            }}>Karaikal Campus</h4>
            {
              karaikalInstitutes.map((object, index) => (
                <Accordion key={index} className="mb-2 custom-accordion">
                  <Accordion.Item eventKey={index.toString()}>
                    <Accordion.Header>{object.institute}</Accordion.Header>
                    <Accordion.Body>
                      {
                        object.course.map((obj, index) => (
                          <div
                            onClick={() => { openBrouchure(object.brouchureLink) }}
                            key={index}
                            className="custom-box">
                            {obj.courseName}
                          </div>
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            }
          </Col>
        </Row>
      </Container> */}

    </>
  )
}

export default Login