import React, { useState, lazy, Suspense } from 'react';
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HouseDoor, List, CardList, Headset, BoxArrowRight } from "react-bootstrap-icons";
import SBVLOGO from '../sbv_logo.png';
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy load components
const Dashboard = lazy(() => import('../Dashboard'));
const AHS_BasicDetails = lazy(() => import('../BasicDetails'));
const AHS_AcademicDetails = lazy(() => import('../AcademicDetails'));
const AHS_DocumentsUpload = lazy(() => import('../DocumentUpload'));
const AHS_Payment = lazy(() => import('../Payment'));
const MBBS_BDS_BasicDetails = lazy(() => import('../MBBSandBDSapplication'));
const MBBS_BDS_DocumentsUpload = lazy(() => import('../MBBS_BDS_DocumentsUpload'));
const MBBS_BDS_Payment = lazy(() => import('../MBBSandBDSPayment'));
const MDMS_BasicDetails = lazy(() => import('../MDMSapplication'));
const MDMS_DocumentsUpload = lazy(() => import('../MDMSdocumentsUpload'));
const MDMS_Payment = lazy(() => import('../MDMSpayment'));
const Phd_BasicDetails = lazy(() => import('../PhdApplication'));
const Phd_DocumentsUpload = lazy(() => import('../PhdDocumentsUpload'));
const Phd_Payment = lazy(() => import('../PhdPayment'));
const EDP_BasicDetails = lazy(() => import('../EDP_FellowshipApplication'));
const EDP_DocumentsUpload = lazy(() => import('../EDP_FellowshipDocumentsUpload'));
const EDP_Payment = lazy(() => import('../EDP_FellowshipPayment'));
const Fugra_Ougra_BasicDetails = lazy(() => import('../fellowshipApplication'));
const Fugra_Ougra_DocumentsUpload = lazy(() => import('../FellowshipDocumentsUpload'));
const Fugra_Ougra_Payment = lazy(() => import('../FellowshipPayment'));
const Support = lazy(() => import('./Support'));
const ViewAhsApplication = lazy(() => import('../ViewApplication'));
const Sidebar = () => {
    var domain = localStorage.getItem('domain');
    const [currentComponent, setCurrentComponent] = useState('dashboard');
    const navigate = useNavigate();
    const [sharedData, setSharedData] = useState({});
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleonDataUpdate = (data) => {
        setSharedData(data);
        setCurrentComponent(data.nextPage);
    };

    // Function to render the appropriate component based on domain and current selection
    const renderComponent = () => {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                {currentComponent === 'dashboard' && <Dashboard onDataUpdate={handleonDataUpdate} />}
                {currentComponent === 'basic-details' &&
                    (domain === "UG/PG_AHS_BMS_N_PT_Medical_SCM" ?
                        <AHS_BasicDetails onDataUpdate={handleonDataUpdate} /> :
                        domain === "MBBS/BDS" ?
                            <MBBS_BDS_BasicDetails onDataUpdate={handleonDataUpdate} /> :
                            domain === "MDMS" ?
                                <MDMS_BasicDetails onDataUpdate={handleonDataUpdate} /> :
                                domain === "Ph.D" ?
                                    <Phd_BasicDetails onDataUpdate={handleonDataUpdate} /> :
                                    domain === "EDP" ?
                                        <EDP_BasicDetails onDataUpdate={handleonDataUpdate} /> :
                                        domain === "FUGRA/OUGRA" ?
                                            <Fugra_Ougra_BasicDetails onDataUpdate={handleonDataUpdate} /> : null)

                }
                {currentComponent === 'academic-details' &&
                    (domain === "UG/PG_AHS_BMS_N_PT_Medical_SCM" ?
                        <AHS_AcademicDetails
                            onDataUpdate={handleonDataUpdate}
                            selectedDegree={sharedData.degree}
                            selectedCourse={sharedData.course}
                        /> : null)
                }
                {currentComponent === 'documents-upload' &&
                    (domain === "UG/PG_AHS_BMS_N_PT_Medical_SCM" ?
                        <AHS_DocumentsUpload
                            onDataUpdate={handleonDataUpdate}
                        /> :
                        domain === "MBBS/BDS" ?
                            <MBBS_BDS_DocumentsUpload onDataUpdate={handleonDataUpdate} /> :
                            domain === "MDMS" ?
                                <MDMS_DocumentsUpload onDataUpdate={handleonDataUpdate} /> :
                                domain === "Ph.D" ?
                                    <Phd_DocumentsUpload onDataUpdate={handleonDataUpdate} /> :
                                    domain === "EDP" ?
                                        <EDP_DocumentsUpload onDataUpdate={handleonDataUpdate} /> :
                                        domain === "FUGRA/OUGRA" ?
                                            <Fugra_Ougra_DocumentsUpload onDataUpdate={handleonDataUpdate} /> : null)
                }
                {currentComponent === 'payment-details' &&
                    (domain === "UG/PG_AHS_BMS_N_PT_Medical_SCM" ?
                        <AHS_Payment onDataUpdate={handleonDataUpdate} /> :
                        domain === "MBBS/BDS" ?
                            <MBBS_BDS_Payment onDataUpdate={handleonDataUpdate} /> :
                            domain === "MDMS" ?
                                <MDMS_Payment onDataUpdate={handleonDataUpdate} /> :
                                domain === "Ph.D" ?
                                    <Phd_Payment onDataUpdate={handleonDataUpdate} /> :
                                    domain === "EDP" ?
                                        <EDP_Payment onDataUpdate={handleonDataUpdate} /> :
                                        domain === "FUGRA/OUGRA" ?
                                            <Fugra_Ougra_Payment onDataUpdate={handleonDataUpdate} /> : null)
                }
                {currentComponent === 'support' && <Support />}
                {currentComponent === 'view-ahs-application' && <ViewAhsApplication />}
            </Suspense>
        );
    };

    // Navigation handler
    const handleNavigation = (component) => {
        setCurrentComponent(component);
    };

    // Add custom styles object
    const navLinkStyles = {
        fontSize: "1rem",
        padding: "12px 15px",
        transition: "all 0.3s ease",
        display: "flex",
        alignItems: "flex-start",
        gap: "10px",
        lineHeight: "1.2",
        whiteSpace: "normal",
        wordWrap: "break-word",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            transform: "translateX(5px)"
        }
    };

    const iconStyles = {
        width: "21px",
        height: "21px",
        marginTop: "2px", // Adjust icon vertical alignment
        flexShrink: 0 // Prevent icon from shrinking
    };

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <div className="d-flex">
            {/* Sidebar */}
            <div className={`sidebar d-flex flex-column p-3 text-white vh-100 ${isCollapsed ? 'collapsed' : ''}`}
                style={{
                    width: isCollapsed ? "70px" : "280px",
                    transition: "width 0.3s",
                    backgroundColor: "#013ea2"
                }}>

                {/* Navigation Links */}
                <Nav className="flex-column">
                    <div className="d-flex justify-content-center">
                        <img src={SBVLOGO}
                            style={{
                                width: isCollapsed ? '40px' : '70px',  // Smaller when collapsed
                                height: isCollapsed ? '50px' : '90px', // Adjust height accordingly
                                transition: 'all 0.3s ease'  // Smooth transition
                            }}
                            alt="SBV LOGO"
                            className='mb-3'
                        />
                    </div>

                    <Nav.Link href="#"
                        className="text-white mb-2"
                        style={navLinkStyles}
                        onClick={() => handleNavigation('dashboard')}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
                            e.currentTarget.style.transform = "translateX(5px)";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                            e.currentTarget.style.transform = "translateX(0)";
                        }}
                    >
                        <HouseDoor style={iconStyles} className="me-2" /> {!isCollapsed && "Dashboard"}
                    </Nav.Link>

                    <Nav.Link href="#"
                        className="text-white mb-2"
                        style={navLinkStyles}
                        onClick={() => handleNavigation('basic-details')}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
                            e.currentTarget.style.transform = "translateX(5px)";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                            e.currentTarget.style.transform = "translateX(0)";
                        }}
                    >
                        <CardList style={iconStyles} className="me-2" /> {!isCollapsed && " New Application Form"}
                    </Nav.Link>

                    <Nav.Link href="#"
                        className="text-white mb-2"
                        style={navLinkStyles}
                        onClick={() => handleNavigation('support')}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
                            e.currentTarget.style.transform = "translateX(5px)";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                            e.currentTarget.style.transform = "translateX(0)";
                        }}
                    >
                        <Headset style={iconStyles} className="me-2" /> {!isCollapsed && "support"}
                    </Nav.Link>

                    <Nav.Link href="/applicationselection"
                        className="text-white mb-2 mt-5"
                        style={navLinkStyles}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
                            e.currentTarget.style.transform = "translateX(5px)";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "";
                            e.currentTarget.style.transform = "translateX(0)";
                        }}
                    >
                        <CardList style={iconStyles} />
                        {!isCollapsed && "Admitted Student Entrollment Form"}
                    </Nav.Link>
                </Nav>
            </div>

            {/* Main Content Area */}
            <div className="flex-grow-1" style={{ height: '100vh', overflow: 'hidden', width: '100%' }}>
                {/* Top Navbar */}
                <Navbar className="bg-light justify-content-between p-1 shadow-sm">
                    <Button
                        variant="outline-primary"
                        className="d-flex align-items-center px-2 py-1 shadow-sm rounded-2"
                        style={{ fontSize: "0.8rem" }}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <List size={18} className="me-1" />
                        Menu
                    </Button>
                    <Button
                        className="d-flex align-items-center gap-1 text-white px-3 py-1 rounded"
                        style={{ background: "linear-gradient(45deg, #ff4b2b, #ff416c)", border: "none", fontSize: "0.875rem" }}
                        onClick={logout}
                    >
                        <BoxArrowRight size={16} />
                        Logout
                    </Button>
                </Navbar>

                {/* Main Content */}
                <Container className="mt-4" style={{
                    height: 'calc(100% - 80px)', overflowY: 'auto', margin: '0px', padding: '0px', width: '100%', overflowX: 'auto'
                }}>
                    {renderComponent()}
                </Container>
            </div>
        </div>
    );
};

export default Sidebar;