import Card from 'react-bootstrap/Card';
import * as Icon from 'react-bootstrap-icons';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Spinner from './Spinner';
import {
    EDPapplicationDocUpload,
    getEDPapplicationUploadedDocs,
    deleteEDPapplicationUploadedDoc
} from './services';

function EDP_FellowshipDocumentsUpload({ onDataUpdate }) {
    const [spinner, setspinner] = useState(false);
    const [docname, Setdocname] = useState('');
    const [uploadedDocs, setuploadedDocs] = useState([]);
    const fileInput = React.useRef(null);
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const handleFileUpload = () => {
        fileInput.current.click();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setspinner(true);
            const formData = new FormData();
            formData.append('documentName', docname);
            formData.append('file', file);
            formData.append('id', localStorage.getItem('id'));
            await EDPapplicationDocUpload(formData);
            getUploadedDocs();
            setspinner(false);
        }
        else {
            swal({
                title: "Please Choose File",
                icon: 'info'
            });
        }
    };

    const getUploadedDocs = async () => {
        const result = await getEDPapplicationUploadedDocs({ "id": localStorage.getItem('id') });
        setuploadedDocs(result);
    }

    React.useEffect(() => {
        getUploadedDocs();
    }, []);

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deleteEDPapplicationUploadedDoc({ filedata, id: localStorage.getItem("id") });
            getUploadedDocs();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const next = () => {
        onDataUpdate({
            nextPage: 'payment-details',
        });
    }

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    }, []);

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mt-3' style={{ textAlign: "center", color: '#fd7e14', marginBottom: "5px" }}>
                    <Col><h5>ADMISSION TO EXECUTIVE DEVELOPMENT PROGRAM (EDP) / FELLOWSHIP PROGRAMMES {currentYear}</h5></Col>
                </Row>

                {uploadedDocs.map((data, index) => {
                    return (
                        <Card style={{ padding: 10 }} key={data._id}>
                            <Row >
                                <Col xs={6} sm={3}>{index + 1}</Col>
                                <Col xs={6} sm={3}>{data.documentName}</Col>
                                <Col xs={6} sm={3}>
                                    <Icon.Eye
                                        color="blue"
                                        size={25}
                                        type="button"
                                        onClick={() => viewfile(data)}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="View"
                                    />
                                </Col>
                                <Col xs={6} sm={3}>
                                    <Icon.Trash
                                        color="red"
                                        size={25}
                                        type="button"
                                        onClick={() => deletefile(data)}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Delete"
                                    />
                                </Col>
                            </Row>
                        </Card>
                    )
                })
                }

                <Card style={{ padding: 10 }} className="mt-2">
                    <Row className="mt-1">
                        <Col><strong>Documents Upload</strong></Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={6}>
                            <Form.Select onChange={docnameChange} name='documentName'>
                                <option>Select Document Name</option>
                                <option value="UG Marksheet">UG Marksheet</option>
                                <option value="PG Marksheet">PG Marksheet</option>
                                <option value="UG Degree">UG Degree</option>
                                <option value="PG Degree">PG Degree</option>
                                <option value="UG Provisional">UG Provisional</option>
                                <option value="PG Provisional">PG Provisional</option>
                                <option value="Transfer Certificate">Transfer Certificate</option>
                                <option value="Aadhar Card">Aadhar Card</option>
                                <option value="Community Certificate">Community Certificate</option>
                                <option value="Income Certificate">Income Certificate</option>
                                <option value="Experience Certificate">Experience Certificate</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={6} style={{ textAlign: "center" }}>
                            <input
                                type="file"
                                accept=".pdf"
                                style={{ display: 'none' }}
                                ref={fileInput}
                                onChange={handleFileChange}
                            />
                            <Button size='sm' onClick={handleFileUpload}>
                                <Icon.Upload /> Upload
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col style={{ textAlign: "center" }}>
                            <Button
                                variant="primary"
                                type="button"
                                style={{ alignItems: 'center', width: '150px' }}
                                className="w-70"
                                onClick={next}
                            >
                                Save & Next
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}

export default EDP_FellowshipDocumentsUpload