import React from 'react';
import { Form, Button, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import abvLogo from './sbv_logo.png';

import { registerUser } from './services';

function Register({ stateToggle }) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await registerUser(data);
      swal({
        title: response.message,
        icon: 'success'
      });
      stateToggle();
    } catch (err) {
      swal({
        title: err.response.data.message,
        icon: 'info'
      });
    }
  };

  return (
    <Row style={{
      backgroundColor: 'rgba(255,255,255,0.5)',
      borderRadius: '10px',
      padding: '20px',
      marginRight: '5px'
    }}>
      <Col className="d-flex flex-column justify-content-center">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>Register</h3> <Image src={abvLogo} alt="Logo" style={{ width: '70px', height: '90px' }} />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group className='mt-2' controlId="candidateName">
            <Form.Control {...register('name', { required: true })} type="text" placeholder="Candidate Name" />

            {errors.name && (
              <Form.Text className="text-error" style={{ color: "red" }}>
                Candidate Name is required.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className='mt-2' controlId="formBasicEmail">
            <Form.Control {...register('email', { required: true })} type="email" placeholder="Mail Id" />

            {errors.email && (
              <Form.Text className="text-error" style={{ color: "red" }}>
                Email Id required.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className='mt-2' controlId="phoneNumber">
            <Form.Control {...register('phoneNumber', { required: true })} type="text" placeholder="Phone Number" />

            {errors.email && (
              <Form.Text className="text-error" style={{ color: "red" }}>
                Phone Number is required.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className='mt-2' controlId="domain">
            <Form.Select {...register('domain', { required: true })}>
              <option value="">Select Domain</option>
              <option value="MBBS/BDS">MBBS / BDS</option>
              <option value="MDMS">MD / MS / MDS</option>
              <option value="UG/PG_AHS_BMS_N_PT_Medical_SCM">AHS, Pharmacy, Nursing, Medical, BioMedical, Physiotherapy, Salutogenesis & Complementary Medicine and Health Profession Education</option>
              <option value="Ph.D">Ph.D</option>
              <option value="EDP">Executive Development Program (EDP) / Fellowship Programmes</option>
              <option value="FUGRA/OUGRA">Fellowship / Observership in Ultrasound Guided Regional Anaesthesia</option>
            </Form.Select>

            {errors.domain && (
              <Form.Text className="text-error" style={{ color: "red" }}>
                Domain selection is required.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className='mt-2' controlId="formBasicPassword">
            <Form.Control {...register('password', { required: true, minLength: 6 })} type="password" placeholder="Password" />
            {
              errors.password?.type === 'required' && (
                <Form.Text className="text-error" style={{ color: "red" }}>
                  Password required.
                </Form.Text>
              )
            }

            {
              errors.password?.type === 'minLength' && (
                <Form.Text className="text-error" style={{ color: "red" }}>
                  Password should be minimum 6 character.
                </Form.Text>
              )
            }
          </Form.Group>

          <Form.Group className='mt-2' controlId="formBasicConfirmPassword">
            <Form.Control
              {...register('confirmPassword', {
                required: true,
                minLength: 6,
                validate: (value) => {
                  if (watch('password') !== value) {
                    return "Your passwords do no match";
                  }
                }
              })}
              type="password"
              placeholder="Confirm Password"
            />
            {
              errors.confirmPassword?.type === 'required' && (
                <Form.Text className="text-error" style={{ color: "red" }} >
                  Confirm Password is required.
                </Form.Text>
              )
            }
            {
              errors.confirmPassword?.type === 'minLength' && (
                <Form.Text className="text-error" style={{ color: "red" }}>
                  Confirm Password should be minimum 6 character.
                </Form.Text>
              )
            }

            {
              errors.confirmPassword?.type === 'validate' && (
                <Form.Text className="text-error" style={{ color: "red" }}>
                  {errors.confirmPassword.message}
                </Form.Text>
              )
            }
          </Form.Group>
          <div style={{ textAlign: 'center' }}>
            <Button variant="primary" size='sm' type="submit" className="mt-2">
              Register
            </Button>
          </div>
        </Form>
        <div className="d-flex align-items-center pt-2">
          <span style={{ marginRight: '10px' }}>Already registered?</span>
          <Link to="" onClick={() => stateToggle()} className='register_login'>Login</Link>
        </div>
        <div>
          <span style={{ marginRight: '10px' }}>For any queries:</span>
          <span>9994267920</span>
        </div>
      </Col>
    </Row>
  )
}

export default Register