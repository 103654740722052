import React from 'react';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import useRazorpay from 'react-razorpay';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import {
    getEDPapplicationDataById,
    createRazorpayOrder,
    EDPapplicationPaymentSuccess,
    EDPapplicationPaymentError
} from './services';

function EDP_FellowshipPayment({ onDataUpdate }) {
    const [application, setapplication] = React.useState({});
    const [spinning, setspinning] = React.useState(false);
    const Razorpay = useRazorpay();
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();

    const getApplicationData = async () => {
        const data = await getEDPapplicationDataById({ 'id': localStorage.getItem('id') });
        setapplication(data);
    }

    React.useEffect(() => {
        getApplicationData();
    }, []);

    const createOrder = () => {
        const payload = {
            amount: 500 * 100
        };

        return createRazorpayOrder(payload);
    };

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    }, []);

    const onPayClick = async () => {
        try {
            setspinning(true);
            const order = await createOrder();

            const onSuccessPayment = async (response) => {
                await EDPapplicationPaymentSuccess({ response, id: localStorage.getItem('id') });
                swal({
                    title: "Application Submitted Successfully.",
                    icon: 'success'
                });
                setspinning(false);
                onDataUpdate({
                    nextPage: 'dashboard',
                });
            };
            const option = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                amount: 500 * 100,
                currency: 'INR',
                name: 'Sri Balaji Vidyapeeth',
                order_id: order.id,
                handler: onSuccessPayment,
                modal: {
                    ondismiss: function () {
                        setspinning(false);  // Close button event logic
                    }
                }
            };
            const rzp = new Razorpay(option);

            rzp.on('payment.failed', async (response) => {
                await EDPapplicationPaymentError({ response, "id": localStorage.getItem("id") });
            });

            rzp.on('payment.capture', () => {

            });

            rzp.open();
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Container style={{ textAlign: "center" }}>
                <Row className='mt-3' style={{ textAlign: "center", color: '#fd7e14', marginBottom: "5px" }}>
                    <Col><h5>ADMISSION TO EXECUTIVE DEVELOPMENT PROGRAM (EDP) / FELLOWSHIP PROGRAMMES {currentYear}</h5></Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Card style={{ width: "60%", padding: 16 }}>
                        <Row className='mt-2' style={{ textAlign: "start" }}>
                            <Col xs={12} sm={3}><strong>Name</strong></Col>
                            <Col xs={12} sm={9}>{application.name}</Col>
                        </Row>
                        <Row className='mt-2' style={{ textAlign: "start" }}>
                            <Col xs={12} sm={3}><strong>Institution</strong></Col>
                            <Col xs={12} sm={9}>{application.institute}</Col>
                        </Row>
                        <Row className='mt-2' style={{ textAlign: "start" }}>
                            <Col xs={12} sm={3}><strong>Programme</strong></Col>
                            <Col xs={12} sm={9}>{application.programme}</Col>
                        </Row>
                        <Row className='mt-2' style={{ textAlign: "start" }}>
                            <Col xs={12} sm={3}><strong>Application Fees</strong></Col>
                            <Col xs={12} sm={9}>500</Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                {spinning === false &&
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ width: '70px' }}
                                        onClick={onPayClick}
                                    >
                                        Pay
                                    </Button>
                                }
                                <ClipLoader
                                    color="#36d7b7"
                                    loading={spinning}
                                    size={40}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </Col>
                        </Row>

                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default EDP_FellowshipPayment