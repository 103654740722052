import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import { ApplicationData } from './services';
import Table from 'react-bootstrap/Table';
import { useRef } from 'react';


function ViewApplication() {
    const navigate = useNavigate();
    const [formdata, setformdata] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const ref = useRef();


    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToDashboard = () => {
        localStorage.removeItem("_id");
        navigate("/dashboard");
    };

    const ApplicationFullDetails = async () => {
        try {
            const obj = {
                "_id": localStorage.getItem("_id")
            };
            const result = await ApplicationData(obj);
            setformdata(result);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        ApplicationFullDetails();
    }, []);

    if (loading) {
        return (
            <>
                <div style={{ textAlign: 'center' }}><h4>Loading...</h4></div>
            </>

        );
    }

    if (!formdata) {
        return (
            <>
                <div style={{ textAlign: 'center' }}><h4>Data not found.</h4></div>

            </>

        );
    };

    return (
        <Card className='p-3'>
            <Row className='mt-3'>
                <Col><strong>Degree</strong></Col>
                <Col>{formdata.degree}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Institute</strong></Col>
                <Col>{formdata.institute}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Course</strong></Col>
                <Col>{formdata.course}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Name</strong></Col>
                <Col>{formdata.name}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Gender</strong></Col>
                <Col>{formdata.gender}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Date Of Birth</strong></Col>
                <Col>{formdata.dateofbirth}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Age</strong></Col>
                <Col>{formdata.age}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>House Number / Appartment Name</strong></Col>
                <Col>{formdata.addressLine1}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Street</strong></Col>
                <Col>{formdata.addressLine2}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Locality</strong></Col>
                <Col>{formdata.addressLine3}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Taluk / Nagar</strong></Col>
                <Col>{formdata.addressLine4}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>District</strong></Col>
                <Col>{formdata.district}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>State</strong></Col>
                <Col>{formdata.statte}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Pin Code</strong></Col>
                <Col>{formdata.pincode}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Nationality</strong></Col>
                <Col>{formdata.nationality}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Religion</strong></Col>
                <Col>{formdata.religion}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Community</strong></Col>
                <Col>{formdata.community}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Economically Weaker Section</strong></Col>
                <Col>{formdata.weakerSection}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Aadhar No</strong></Col>
                <Col>{formdata.aadharNo}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Mobile No</strong></Col>
                <Col>{formdata.mobileNo}</Col>
            </Row>
            <Row className='mt-3'>
                <Col><strong>Mail Id</strong></Col>
                <Col>{formdata.mailid}</Col>
            </Row>
            {formdata.course === "Masters In Health Professions Education" &&
                <div>
                    <Row className='mt-3'>
                        <Col><strong>Official Mail Id</strong></Col>
                        <Col>{formdata.officialmailid}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Official Phone No</strong></Col>
                        <Col>{formdata.officialphoneno}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Official / Institutional Address</strong></Col>
                        <Col>{formdata.officialaddress}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>The subject in which the candidate has qualified for the</strong></Col>
                        <Col>{formdata.subjectQualified}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>The above master's Degree was awarded by</strong></Col>
                        <Col>{formdata.degreeAwardedBy}</Col>
                    </Row>
                    {formdata.degreeAwardedBy === "Others" &&
                        <Row className='mt-3'>
                            <Col><strong>If Others, Mention degree awarded by</strong></Col>
                            <Col>{formdata.othersDegreeAwardedBy}</Col>
                        </Row>
                    }
                </div>
            }
            {
                formdata.degree === "UG" && formdata.course !== "Masters In Health Professions Education" &&
                <div>
                    <Row className='mt-3'>
                        <Col><strong>Month / Year of Passing</strong></Col>
                        <Col>{formdata.hscmonthAndYearOfPassing}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Board for HSC</strong></Col>
                        <Col>{formdata.hscinstitution}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Marks in %</strong></Col>
                        <Col>{formdata.hscmarks}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Total Marks</strong></Col>
                        <Col>{formdata.totalMark12}</Col>
                    </Row>
                    <Table striped bordered hover className="mt-3 text-center">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Marks Obtained</th>
                                <th>Maximum Marks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typeof formdata.TFHSObtainedMark === 'number' && (
                                    <tr>
                                        <td>Tamil/French/Hindi/Sanskrit</td>
                                        <td>{formdata.TFHSObtainedMark}</td>
                                        <td>{formdata.TFHSMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.englishObtainedMark === 'number' && (
                                    <tr>
                                        <td>English</td>
                                        <td>{formdata.englishObtainedMark}</td>
                                        <td>{formdata.englishMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.physicsObtainedMark === 'number' && (
                                    <tr>
                                        <td>Physics</td>
                                        <td>{formdata.physicsObtainedMark}</td>
                                        <td>{formdata.physicsMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.chemistryObtainedMark === 'number' && (
                                    <tr>
                                        <td>Chemistry</td>
                                        <td>{formdata.chemistryObtainedMark}</td>
                                        <td>{formdata.chemistryMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.biologyObtainedMark === 'number' && (
                                    <tr>
                                        <td>Biology</td>
                                        <td>{formdata.biologyObtainedMark}</td>
                                        <td>{formdata.biologyMaxMark}</td>
                                    </tr>
                                )
                            }

                            {
                                typeof formdata.botanyObtainedMark === 'number' && (
                                    <tr>
                                        <td>Botany</td>
                                        <td>{formdata.botanyObtainedMark}</td>
                                        <td>{formdata.botanyMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.zoologyObtainedMark === 'number' && (
                                    <tr>
                                        <td>Zology</td>
                                        <td>{formdata.zoologyObtainedMark}</td>
                                        <td>{formdata.zoologyMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.mathematicsObtainedMark === 'number' && (
                                    <tr>
                                        <td>Mathematics</td>
                                        <td>{formdata.mathematicsObtainedMark}</td>
                                        <td>{formdata.mathematicsMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.computerScienceObtainedMark === 'number' && (
                                    <tr>
                                        <td>Computer Science</td>
                                        <td>{formdata.computerScienceObtainedMark}</td>
                                        <td>{formdata.computerScienceMaxMark}</td>
                                    </tr>
                                )
                            }
                            {
                                typeof formdata.othersObtainedMark === 'number' && (

                                    <tr>
                                        <td>Others</td>
                                        <td>{formdata.othersObtainedMark}</td>
                                        <td>{formdata.othersMaxMark}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            }
            {formdata.degree === "PG" && formdata.course !== "Masters In Health Professions Education" &&
                <div>
                    <Row className='mt-3'>
                        <Col><strong>Month / Year of Passing</strong></Col>
                        <Col>{formdata.degremonthAndYearOfPassing}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Institution / University</strong></Col>
                        <Col>{formdata.degreinstitution}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Marks in %</strong></Col>
                        <Col>{formdata.degremarks}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Total Marks Obtained</strong></Col>
                        <Col>{formdata.totalSemesterMarksObtained}</Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col><strong>Total Maximum Marks</strong></Col>
                        <Col>{formdata.totalSemesterMaxMarks}</Col>
                    </Row>
                    <Table striped bordered hover className="mt-3 text-center">
                        <thead>
                            <tr>
                                <th>Semester</th>
                                <th>Month / Year of Passing</th>
                                <th>Marks Obtained</th>
                                <th>Maximum Marks</th>
                                <th>Marks In %</th>
                            </tr>
                        </thead>
                        {
                            <tbody>
                                {
                                    formdata.diplomaDegreeMarks.map((obj) => (
                                        < tr key={obj._id}>
                                            <td>{obj.semester}</td>
                                            <td>{obj.semesterYearOfPassing}</td>
                                            <td>{obj.semesterMarksObtained}</td>
                                            <td>{obj.semesterMaxMarks}</td>
                                            <td>{obj.semesterPercentageMark}</td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        }
                    </Table>
                </div>
            }
            {formdata.course === "Masters In Health Professions Education" &&
                <div>
                    <Row className='mt-3'>
                        <Col><strong>Academic Qualification (UG to Masters)</strong></Col>
                    </Row>
                    <Table striped bordered hover className="mt-3 text-center">
                        <thead>
                            <tr>
                                <th>Qualification</th>
                                <th>School/College</th>
                                <th>Board/University</th>
                                <th>Month & Year of Passing</th>
                                <th>Subject</th>
                                <th>% of Mark</th>
                                <th>Class/Rank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formdata.IHPE_UG_to_Masters.map((obj) => (
                                <tr key={obj._id}>
                                    <td>{obj.IHPEqualification}</td>
                                    <td>{obj.IHPEschool_college}</td>
                                    <td>{obj.IHPEboard_university}</td>
                                    <td>{obj.IHPEmonth_year_passing}</td>
                                    <td>{obj.IHPEsubject}</td>
                                    <td>{obj.IHPEpercentagemark}</td>
                                    <td>{obj.IHPEclass_rank}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                    <Row className='mt-3'>
                        <Col><strong>Professional/Teaching Experience</strong></Col>
                    </Row>
                    <Table striped bordered hover className="mt-3 text-center">
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Institution</th>
                                <th>From Date</th>
                                <th>To Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formdata.experience.map((obj) => (
                                <tr key={obj._id}>
                                    <td>{obj.expDesignation}</td>
                                    <td>{obj.expInstitution}</td>
                                    <td>{obj.expFromDate}</td>
                                    <td>{obj.expToDate}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </div>
            }
            <Row className='mt-3'>
                <Col><strong>uploaded Documents List</strong></Col>
            </Row>
            {
                formdata.uploadDocuments.map((obj) => (
                    <Row className='mt-3' key={obj._id}>
                        <Col>{obj.documentName}</Col>
                    </Row>
                ))
            }
        </Card>
    )
}

export default ViewApplication;