import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Row, Col, Card, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import swal from 'sweetalert';
import { useForm, useFieldArray } from 'react-hook-form';
import {
    saveEDPapplicationData
} from './services';

function EDP_FellowshipApplication({ onDataUpdate }) {
    const [spinner, setspinner] = React.useState(false);
    const [photoSrc, setphotoSrc] = React.useState("");
    const [photo, setphoto] = React.useState();
    const fileInput = React.useRef(null);

    const currentYear = new Date().getFullYear();

    const { register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control
    } = useForm();

    const {
        fields: academicQualificationFields,
        append: appendAcademicQualificationItems,
        remove: removeAcademicQualificationItems
    } = useFieldArray(
        {
            control,
            name: 'academicQualification'
        }
    )

    const {
        fields: experienceFields,
        append: appendExperienceFields,
        remove: removeExperienceFields
    } = useFieldArray(
        {
            control,
            name: 'experience'
        }
    )

    if (experienceFields.length === 0) {
        appendExperienceFields();
    };

    if (academicQualificationFields.length === 0) {
        appendAcademicQualificationItems();
    };

    const selectedInstitute = watch('institute');
    const selectedProgramme = watch('programme');
    const selectedDepartment = watch('department');
    const degreeAwarded = watch('degreeAwardedBy');
    const navigate = useNavigate();

    const validateDynamic = (value) => {
        if (degreeAwarded === "Others") {
            if (!value) {
                return "Degree Awarded By Field is Empty"
            }
        }
    }

    const validateClassRank = (value) => {
        if (value === '0') {
            return "Please enter a value other than 0";
        }
        return true;
    };

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    }, []);

    React.useEffect(() => {
        if (selectedProgramme === "EDP / Fellowship In Maternal & Child Health") {
            setValue('department', 'Peadiatrics');
        }
        if (selectedProgramme === "EDP / Fellowship In Diabetes" || selectedProgramme === "EDP / Fellowship In Diabetes Education") {
            setValue('department', 'General Medicine');
        }
        if (selectedProgramme === "EDP / Fellowship In E-Learning In Health Professions Education") {
            setValue('department', 'Medical Informatics');
        }
        if (selectedProgramme === "EDP / Fellowship In Basic Otology") {
            setValue('department', 'ENT');
        }
        if (selectedProgramme === "EDP / Fellowship In Hospital Infection Control" || selectedProgramme === "EDP / Fellowship In Tropical Parasitology") {
            setValue('department', 'Microbiology');
        }
        if (selectedProgramme === "EDP / Fellowship In Surgical Management Of Gender Dysphoria (male to female)") {
            setValue('department', 'General Surgery');
        }
        if (selectedProgramme === "EDP / Fellowship In Dermatopathology") {
            setValue('department', 'Pathology & Dermatology');
        }
        if (selectedProgramme === "EDP / Community Ophthalmology") {
            setValue('department', 'Ophthalmology');
        }
        if (selectedProgramme === "EDP / Fellowship In Obstetrics & Gynaecology Ultrasound") {
            setValue('department', 'Obgy');
        }
        if (selectedProgramme === "EDP / Fellowship In Disaster Management" || selectedProgramme === "EDP / Fellowship In Pandemic Management") {
            setValue('department', 'Community Medicine');
        }
        if (selectedProgramme === "EDP / Fellowship In Human Genetics") {
            setValue('department', 'Anatomy');
        }
        if (selectedProgramme === "EDP In Rotary Endodontics") {
            setValue('department', 'Conservative Dentistry and Endodontics');
        }
        if (selectedProgramme === "EDP In Oral Radiology") {
            setValue('department', 'Oral Medicine and Radiology');
        }
        if (selectedProgramme === "EDP In Dental Hospital Infection Control") {
            setValue('department', 'Oral and Maxillofacial Pathology & Oral Microbiology');
        }
        if (selectedProgramme === "EDP In Maxillofacial Trauma") {
            setValue('department', 'Oral & Maxillofacial Surgery');
        }
        if (selectedProgramme === "EDP In Aligners") {
            setValue('department', 'Orthodontics & Dentofacial Orthopaedics');
        }
        if (selectedProgramme === "EDP In Special Care Dentistry for Children") {
            setValue('department', 'Pediatric & Preventive Dentistry');
        }
        if (selectedProgramme === "EDP In Gingival and Perio Plastic Surgical Techniques") {
            setValue('department', 'Periodontology');
        }
        if (selectedProgramme === "EDP In Special Needs Dental Health Programs") {
            setValue('department', 'Public Health Dentistry');
        }
        if (selectedProgramme === "EDP In Implantology") {
            setValue('department', 'Prosthodontics and Crown & Bridge');
        }
    }, [selectedProgramme])

    const submit = async (data) => {
        if (!photo) {
            swal({
                title: "Please Upload Photo",
                icon: 'info'
            });
        }
        else {
            swal({
                title: "Please verify all details before submit. Once submited don't editable.",
                icon: 'warning',
                buttons: {
                    cancel: {
                        text: "Verify",
                        value: null,
                        visible: true,
                        className: "custom-cancel-button",
                        closeModal: true,
                    },
                    confirm: {
                        text: "Submit",
                        value: true,
                        visible: true,
                        className: "custom-submit-button",
                        closeModal: true,
                    },
                }
            }).then(async (value) => {
                if (value) {
                    setspinner(true);
                    data.username = localStorage.getItem('username');
                    const formData = new FormData();
                    formData.append('data', JSON.stringify(data));
                    formData.append('file', photo);
                    const result = await saveEDPapplicationData(formData);
                    localStorage.setItem("id", result.id);
                    setspinner(false);
                    onDataUpdate({
                        nextPage: 'documents-upload',
                    });
                }
            })
        }
    };

    const handleFileUpload = () => {
        fileInput.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // You can perform the upload logic here
        setphoto(file);
        setphotoSrc(URL.createObjectURL(file));
    };

    const getProgrammeOptions = () => {
        if (selectedInstitute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
            return (
                <React.Fragment>
                    <option>Select Programme</option>
                    <option value="EDP / Fellowship In Maternal & Child Health">EDP / Fellowship In Maternal & Child Health</option>
                    <option value="EDP / Fellowship In Diabetes">EDP / Fellowship In Diabetes</option>
                    <option value="EDP / Fellowship In E-Learning In Health Professions Education">EDP / Fellowship In E-Learning In Health Professions Education</option>
                    <option value="EDP / Fellowship In Diabetes Education">EDP / Fellowship In Diabetes Education</option>
                    <option value="EDP / Fellowship In Basic Otology">EDP / Fellowship In Basic Otology</option>
                    <option value="EDP / Fellowship In Hospital Infection Control">EDP / Fellowship In Hospital Infection Control</option>
                    <option value="EDP / Fellowship In Tropical Parasitology">EDP / Fellowship In Tropical Parasitology</option>
                    <option value="EDP / Fellowship In Surgical Management Of Gender Dysphoria (male to female)">EDP / Fellowship In Surgical Management Of Gender Dysphoria (male to female)</option>
                    <option value="EDP / Fellowship In Dermatopathology">EDP / Fellowship In Dermatopathology</option>
                    <option value="EDP / Fellowship In Community Ophthalmology">EDP / Fellowship In Community Ophthalmology</option>
                    <option value="EDP / Fellowship In Obstetrics & Gynaecology Ultrasound">EDP / Fellowship In Obstetrics & Gynaecology Ultrasound</option>
                    <option value="EDP / Fellowship In Disaster Management">EDP / Fellowship In Disaster Management</option>
                    <option value="EDP / Fellowship In Pandemic Management">EDP / Fellowship In Pandemic Management</option>
                    <option value="EDP / Fellowship In Human Genetics">EDP / Fellowship In Human Genetics</option>
                </React.Fragment>
            )
        }
        if (selectedInstitute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
            return (
                <React.Fragment>
                    <option>Select Programme</option>
                    <option value="EDP / Fellowship In Diabetes">EDP / Fellowship In Diabetes</option>
                    <option value="EDP / Fellowship In Basic Otology">EDP / Fellowship In Basic Otology</option>
                    <option value="EDP / Fellowship In Hospital Infection Control">EDP / Fellowship In Hospital Infection Control</option>
                    <option value="EDP / Fellowship In Tropical Parasitology">EDP / Fellowship In Tropical Parasitology</option>
                    <option value="EDP / Fellowship In Community Ophthalmology">EDP / Fellowship In Community Ophthalmology</option>
                </React.Fragment>
            )
        }
        if (selectedInstitute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
            return (
                <React.Fragment>
                    <option>Select Programme</option>
                    <option value="EDP In Rotary Endodontics">EDP In Rotary Endodontics</option>
                    <option value="EDP In Oral Radiology">EDP In Oral Radiology</option>
                    <option value="EDP In Dental Hospital Infection Control">EDP In Dental Hospital Infection Control</option>
                    <option value="EDP In Maxillofacial Trauma">EDP In Maxillofacial Trauma</option>
                    <option value="EDP In Aligners">EDP In Aligners</option>
                    <option value="EDP In Special Care Dentistry for Children">EDP In Special Care Dentistry for Children</option>
                    <option value="EDP In Gingival and Perio Plastic Surgical Techniques">EDP In Gingival and Perio Plastic Surgical Techniques</option>
                    <option value="EDP In Special Needs Dental Health Programs">EDP In Special Needs Dental Health Programs</option>
                    <option value="EDP In Implantology">EDP In Implantology</option>
                </React.Fragment>
            )
        }
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>

            <Form onSubmit={handleSubmit(submit)}>
                <Row style={{ textAlign: "center", color: '#fd7e14' }}>
                    <Col><h5>ADMISSION TO EXECUTIVE DEVELOPMENT PROGRAM (EDP) / FELLOWSHIP PROGRAMMES {currentYear}</h5></Col>
                </Row>
                <Card style={{ padding: 10 }}>
                    <Row className='mt-2'>
                        <Col sm={10}>
                            <Row className=''>
                                <Col xs={12} sm={6}><strong>Name of the Applicant</strong><br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(in P.G. Degree Certificate)</span>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('name', { required: "Name field is empty." })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.name &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.name.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} sm={6}><strong>Select Institute</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select
                                        {...register('institute', {
                                            required: "Please Select Institute"
                                        })}
                                    >
                                        <option value="">Please Select</option>
                                        <option value="Mahatma Gandhi Medical College & Research Institute - MGMCRI">Mahatma Gandhi Medical College & Research Institute - MGMCRI</option>
                                        <option value="Shri Sathya Sai Medical College & Research Institute - SSSMCRI">Shri Sathya Sai Medical College & Research Institute - SSSMCRI</option>
                                        <option value="Indira Gandhi Institute Of Dental Sciences - IGIDS">Indira Gandhi Institute Of Dental Sciences - IGIDS</option>
                                    </Form.Select>
                                    {
                                        errors?.institute &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.institute.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}><strong>Select Programme</strong></Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select
                                        {...register('programme', {
                                            required: "Please Select Programme"
                                        })}
                                    >
                                        {getProgrammeOptions()}
                                    </Form.Select>
                                    {
                                        errors?.programme &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.programme.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>
                            <div style={{ width: "112px", height: "152px", border: "1px solid", marginLeft: "25px" }}>
                                <img src={photoSrc} width={110} height={150} alt='photo'></img>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "5px" }}>
                                <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    ref={fileInput}
                                    onChange={handleFileChange}
                                />
                                <Button size='sm' onClick={handleFileUpload}>
                                    <Icon.Upload /> Upload
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Department</strong></Col>
                        <Col xs={12} sm={6}>{selectedDepartment}</Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Gender</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Group
                            >
                                <Form.Check
                                    inline
                                    label="Male"
                                    type="radio"
                                    value="Male"
                                    {...register('gender', { required: "Please Select Gender" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="Female"
                                    value="Female"
                                    type="radio"
                                    {...register('gender', { required: "Please Select Gender" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="Third Gender"
                                    type="radio"
                                    value="Third Gender"
                                    {...register('gender', { required: "Please Select Gender" })}
                                >
                                </Form.Check>
                            </Form.Group>
                            {
                                errors?.gender &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.gender.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Date of Birth</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='Date'
                                {...register("dateofbirth", { required: "Please Select Your Date of Birth", pattern: /^\d{4}-\d{2}-\d{2}$/ })}
                            >
                            </Form.Control>
                            {
                                errors?.dateofbirth &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.dateofbirth.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Community</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Check
                                    inline
                                    label="SC"
                                    type="radio"
                                    value="SC"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="ST"
                                    type="radio"
                                    value="ST"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="MBC"
                                    type="radio"
                                    value="MBC"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="BC"
                                    type="radio"
                                    value="BC"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="OBC"
                                    type="radio"
                                    value="OBC"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="OC"
                                    type="radio"
                                    value="OC"
                                    {...register('community', { required: "Please Select Community" })}
                                >
                                </Form.Check>
                            </Form.Group>
                            {
                                errors?.community &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.community.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Religion</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Select
                                {...register('religion', { required: "Please Select Religion" })}
                            >
                                <option value="">Select Religion</option>
                                <option value="Hindu">Hindu</option>
                                <option value="Muslim">Muslim</option>
                                <option value="Christian">Christian</option>
                                <option value="Buddhist">Buddhist</option>
                                <option value="Jain">Jain</option>
                                <option value="Sikh">Sikh</option>
                                <option value="Others">Others</option>
                            </Form.Select>
                            {
                                errors?.religion &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.religion.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Nationality</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                {...register('nationality', { required: "Nationality Field is Empty" })}
                            >
                            </Form.Control>
                            {
                                errors?.nationality &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.nationality.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='fatherName'
                                {...register('fatherName', { required: "Father / Guardian Name Field is Empty" })}
                            >
                            </Form.Control>
                            {
                                errors?.fatherName &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.fatherName.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Residential Address</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                as="textarea"
                                {...register('residentialaddress', { required: "Residential Address Field is Empty" })}
                            >
                            </Form.Control>
                            {
                                errors?.residentialaddress &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.residentialaddress.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Office / Institution Address</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                as="textarea"
                                {...register('officeaddress', { required: "Office / Institution Address Field is Empty" })}
                            >
                            </Form.Control>
                            {
                                errors?.officeaddress &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.officeaddress.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Aadhar Number</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                {
                                ...register('aadharNo',
                                    {
                                        required: "Aadhar Number Field is Empty",
                                        pattern: {
                                            value: /^[0-9]{4} [0-9]{4} [0-9]{4}$/,
                                            message: "Aadhar Number Should be in this Formate 1111 2222 3333"
                                        }
                                    }
                                )
                                }
                            >
                            </Form.Control>
                            {
                                errors?.aadharNo &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.aadharNo.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Mobile Number</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="number"
                                {
                                ...register('mobileNo',
                                    {
                                        required: "Mobile Number Field is Empty",
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: "Please Enter a Valid 10 Digit Phone Number"
                                        }
                                    }
                                )
                                }
                            >
                            </Form.Control>
                            {
                                errors?.mobileNo &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.mobileNo.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Mail Id</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='email'
                                {
                                ...register('mailId',
                                    {
                                        required: "Mail Id Field is Empty",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.+com$/,
                                            message: "Please Enter a Valid Email Address"
                                        }
                                    }
                                )
                                }
                            >
                            </Form.Control>
                            {
                                errors?.mailId &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.mailId.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Official Mail Id</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='email'
                                {
                                ...register('officialmailid',
                                    {
                                        required: "Official Mail Id Field is Empty",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: "Please Enter a Valid Email Address"
                                        }
                                    }
                                )
                                }
                            >
                            </Form.Control>
                            {
                                errors?.officialmailid &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.officialmailid.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>Official Phone No</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type="number"
                                {
                                ...register('officialphoneno',
                                    {
                                        required: "Official Phone Number Field is Empty",
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: "Please Enter a Valid 10 Digit Phone Number"
                                        }
                                    }
                                )
                                }
                            >
                            </Form.Control>
                            {
                                errors?.officialphoneno &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.officialphoneno.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={10}><strong>Academic Qualification (UG to M.Phil)</strong></Col>
                        <Col xs={12} sm={2}>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => appendAcademicQualificationItems()}
                            >
                                Add Row
                            </Button>
                        </Col>
                        <Row className="mt-2">
                            <Col xs={3} sm={2}>Qualification</Col>
                            <Col xs={3} sm={2}>School/College</Col>
                            <Col xs={3} sm={2}>Board/University</Col>
                            <Col xs={3} sm={1}>Month & Year of Passing</Col>
                            <Col xs={3} sm={2}>subject</Col>
                            <Col xs={3} sm={1}>% of Mark</Col>
                            <Col xs={3} sm={1}>Class/ Rank</Col>
                            <Col xs={3} sm={1}></Col>
                        </Row>
                        {academicQualificationFields &&
                            academicQualificationFields.map((item, index) => {
                                return (
                                    <Row className="mt-2" key={item.id}>
                                        <Col xs={3} sm={2}>
                                            <Form.Control
                                                type='text'
                                                {...register(`academicQualification.${index}.qualification`, { required: "Qualification Field is Empty" })}
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.qualification &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.qualification?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={2}>
                                            <Form.Control
                                                type='text'
                                                {...register(`academicQualification.${index}.school_college`, { required: "School / College Field is Empty" })}
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.school_college &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.school_college?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={2}>
                                            <Form.Control
                                                type='text'
                                                {...register(`academicQualification.${index}.board_university`, { required: "Board / University Field is Empty" })}
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.board_university &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.board_university?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={1}>
                                            <Form.Control
                                                type='text'
                                                {...register(
                                                    `academicQualification.${index}.month_and_yearofpassing`,
                                                    {
                                                        required: "Month & Year of Passing Field is Empty",
                                                        pattern: {
                                                            value: /^[0-9]{4}$/,
                                                            message: "Please Enter a Valid Year yyyy"
                                                        }
                                                    }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.month_and_yearofpassing &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.month_and_yearofpassing?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={2}>
                                            <Form.Control
                                                type='text'
                                                {...register(`academicQualification.${index}.subject`, { required: "Subject Field is Empty" })}
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.subject &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.subject?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={1}>
                                            <Form.Control
                                                type='text'
                                                {...register(
                                                    `academicQualification.${index}.percentage_of_mark`,
                                                    {
                                                        required: "% of Mark Field is Empty",
                                                        pattern: {
                                                            value: /^(100|[1-9][0-9]?)$/,
                                                            message: "Percentage should be a number between 1 and 100"
                                                        }
                                                    }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.percentage_of_mark &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.percentage_of_mark?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={1}>
                                            <Form.Control
                                                type='text'
                                                {...register(
                                                    `academicQualification.${index}.class_rank`,
                                                    {
                                                        required: "Class / Rank Field is Empty",
                                                        validate: validateClassRank
                                                    }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                            {
                                                errors.academicQualification?.[index]?.class_rank &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.academicQualification?.[index]?.class_rank?.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                        <Col xs={3} sm={1} style={{ textAlign: "center" }}>
                                            <Icon.Trash
                                                color="red"
                                                size={25}
                                                type="button"
                                                onClick={() => removeAcademicQualificationItems(index)}
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Delete Row"
                                            />
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={10}><strong>Professional / Teaching Experience</strong></Col>
                        <Col xs={12} sm={2}>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => appendExperienceFields()}
                            >
                                Add Row
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2" >
                        <Col xs={6} sm={3}>Designation</Col>
                        <Col xs={6} sm={4}>Institution</Col>
                        <Col xs={6} sm={2}>From Date</Col>
                        <Col xs={6} sm={2}>To Date</Col>
                        <Col xs={6} sm={1}></Col>
                    </Row>
                    {experienceFields &&
                        experienceFields.map((item, index) => {
                            return (
                                <Row className="mt-2" key={item.id}>
                                    <Col xs={6} sm={3}>
                                        <Form.Control
                                            type='text'
                                            {...register(`experience.${index}.designation`, { required: "Designation Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors.experience?.[index]?.designation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.experience?.[index]?.designation?.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={4}>
                                        <Form.Control
                                            type='text'
                                            {...register(`experience.${index}.institution`, { required: "Institution Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors.experience?.[index]?.institution &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.experience?.[index]?.institution?.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={2}>
                                        <Form.Control
                                            type='text'
                                            {...register(`experience.${index}.fromDate`,
                                                {
                                                    required: "From Date Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
                                                        message: "Please Enter a Date This Formate dd-mm-yyyy"
                                                    }
                                                }
                                            )}
                                        >
                                        </Form.Control>
                                        {
                                            errors.experience?.[index]?.fromDate &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.experience?.[index]?.fromDate?.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={2}>
                                        <Form.Control
                                            type='text'
                                            {
                                            ...register(`experience.${index}.toDate`,
                                                {
                                                    required: "To Date Field is Empty",
                                                    pattern: {
                                                        value: /^\d{2}-\d{2}-\d{4}$/,
                                                        message: "Please Enter a Date This Formate dd-mm-yyyy"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors.experience?.[index]?.toDate &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.experience?.[index]?.toDate?.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                    <Col xs={6} sm={1} style={{ textAlign: "center" }}>
                                        <Icon.Trash
                                            color="red"
                                            size={25}
                                            type="button"
                                            onClick={() => removeExperienceFields(index)}
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Delete Row"
                                        />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>The subject in which the candidate has qualified for the</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Select
                                {...register('qualifiedDegree', { required: "Please Select Qualified Degree" })}
                            >
                                <option value="">Please Select</option>
                                <option value="Master's Degree">Master's Degree</option>
                                <option value="Doctoral Degree">Doctoral Degree</option>
                                <option value="Post Doctoral Degree">Post Doctoral Degree</option>
                            </Form.Select>
                            {
                                errors.qualifiedDegree &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.qualifiedDegree.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                <Card style={{ padding: 10 }}>
                    <Row>
                        <Col xs={12} sm={6}><strong>The above master's Degree was awarded by</strong></Col>
                        <Col xs={12} sm={6}>
                            <Form.Select
                                {...register('degreeAwardedBy', { required: "Please Select Degree Awarded Department" })}
                            >
                                <option value="">Please Select</option>
                                <option value="Faculty of Management">Faculty of Management</option>
                                <option value="Faculty of Engineering">Faculty of Engineering</option>
                                <option value="Faculty of Dentistry">Faculty of Dentistry</option>
                                <option value="Faculty of Nursing">Faculty of Nursing</option>
                                <option value="Faculty of Science">Faculty of Science</option>
                                <option value="Faculty of Allied Health Sciences">Faculty of Allied Health Sciences</option>
                                <option value="Faculty of Medicine">Faculty of Medicine</option>
                                <option value="Others">Others</option>
                            </Form.Select>
                            {
                                errors.degreeAwardedBy &&
                                <Form.Text className="text-error" style={{ color: "red" }}>
                                    {errors.degreeAwardedBy.message}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                </Card>
                {degreeAwarded === "Others" &&
                    <Card style={{ padding: 10 }}>
                        <Row>
                            <Col xs={12} sm={6}><strong>If Others, Mention degree awarded by</strong></Col>
                            <Col xs={12} sm={6}>
                                <Form.Control
                                    type='text'
                                    {...register('othersDegreeAwardedBy', { validate: validateDynamic })}
                                >
                                </Form.Control>
                                {
                                    errors.othersDegreeAwardedBy &&
                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                        {errors.othersDegreeAwardedBy.message}
                                    </Form.Text>
                                }
                            </Col>
                        </Row>
                    </Card>
                }
                <Row className='mt-3 mb-5'>
                    <Col style={{ textAlign: "center" }}>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ alignItems: 'center', width: '150px' }}
                            className="w-70"
                        >
                            Save & Next
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default EDP_FellowshipApplication