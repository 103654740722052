import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import { forgotpassword } from './services';

function Forgotpassword({ stateToggle }) {
    const { register, handleSubmit } = useForm();
    const submit = async (data) => {
        try {
            const result = await forgotpassword(data);
            swal({
                title: result.message,
                icon: 'success'
            }).then(function () {
                stateToggle();
            })
        } catch (err) {
            console.log(err);
            swal({
                title: err.response.data.message,
                icon: 'error'
            });
        }
    }
    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{
                height: '300px',
                backgroundColor: 'rgba(255,255,255,0.5)',
                borderRadius: '10px',
                padding: '20px',
                marginRight: '5px',
            }}>
            <div>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Form onSubmit={handleSubmit(submit)}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Enter Your Registered Mail Id</Form.Label>
                                <Form.Control {...register('email', { required: true })} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <Button variant="primary" type="submit" className="mt-3">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Container>



    )
}

export default Forgotpassword