import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Telephone, Envelope, GeoAlt } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Support = () => {
    return (
        <Container className="mt-4">
            <Card className="p-4 shadow">
                <Row>
                    <Col md={6}>
                        <h4 className="text-danger">To find us</h4>
                        <p>Our team is waiting to help you!</p>
                        <p><Telephone /> <a href="">0413 - 2611807, 808, 809, 99942 67920</a></p>
                        <p><Envelope /> <a href="mailto:admissions@sbvu.ac.in">admissions@sbvu.ac.in</a></p>
                        <strong>For technical query:</strong>
                        <p><Telephone /> <a href="">0413- 2616707</a></p>
                        <p><Envelope /> <a href="mailto:programmer@mgmcri.ac.in">programmer@mgmcri.ac.in </a></p>
                        <p><GeoAlt /> Sri Balaji Vidyapeeth, SBV Campus, Pillaiyarkuppam, Pondicherry - 607 402. India.</p>
                    </Col>
                    <Col md={6}>
                        <h4 className="text-danger">Location</h4>
                        <iframe
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3905.323861754846!2d79.77564607379055!3d11.812576039230638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a549f24c197787d%3A0x38993ef8a145a398!2sSri%20Balaji%20Vidyapeeth%20(SBVU)!5e0!3m2!1sen!2sin!4v1740647793863!5m2!1sen!2sin"
                            width="100%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Col>
                </Row>
            </Card>
        </Container>
    );
}

export default Support;