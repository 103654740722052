import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import Card from 'react-bootstrap/Card';
import swal from 'sweetalert';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import {
    ApplicationList,
    DownloadApplication,
    DownloadHallTicket,
    getCourseName,
    getPhdApplicationList,
    getFellowshipApplicationList,
    getMBBSandBDSapplicationList,
    getMDMSapplicationList,
    getStudentRegistrationListForDashboard,
    downloadAllDeclarationsInPDF,
    getEDPapplicationListForDashboard,
    downloadEDPapplicationForm,
    get_MD_MS_MDS_StudentRegistrationList,
    get_MD_MCh_StudentRegistrationList,
    get_AHS_StudentRegistrationList,
    get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList,
    get_BPharm_StudentRegistrationList,
    get_BPT_MPT_StudentRegistrationList,
    get_MSc_Medical_MBA_MPH_StudentRegistrationList,
    studentRegistration_AHS_DownloadDeclaration,
    studentRegistration_BPharm_DownloadDeclaration,
    studentRegistrationMD_MS_MDS_DownloadDeclaration,
    studentRegistration_DM_MCh_DownloadDeclaration,
    studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration,
    studentRegistration_BPT_MPT_DownloadDeclaration,
    studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations,
    phdApplicationDownload

} from './services';
import Table from 'react-bootstrap/Table';
const callLetterCourses =
    [
        "Poat Graduate Diploma In Music Therapy",
        "M.Sc.Medical Music Therapy",
        "Post Graduate Certificate In Yoga Therapy",
        "Post Graduate Diploma In Yoga Therapy",
        "M.Sc.Yoga Therapy",
        "Masters In Health Professions Education",
        "B.Sc. in Biomedical Sciences",
        "B.Sc.(Hons) in Biomedical Sciences",
        "M.Sc. in Biomedical Sciences",
        "M.Sc. + Ph.D. in Biomedical Sciences",
        "PG Diploma in Bio-Industrial Applications",
        "M.Sc.Medical MicroBiology",
        "M.Sc.Medical Biochemistry",
        "M.Sc.Nurse Practitioner In Critical Care Nursing",
        "Post Graduate Certificate In Health  Science Library Information Technology",
        "Post Graduate Diploma In Pharmacogenomics",
        "Post Graduate Diploma In Good Clinic Laboratory Practices",
        "M.Sc.Medical Laboratory Technology In Clinical Chemistry",
        "M.Sc.Clinical Nutrition",
        "M.Sc.Medical Laboratory Technology In Pathology",
        "M.Sc.Echocardiography",
        "M.Sc.Cardiac Catheterization And Intervention",
        "M.Sc.Medical Imaging Technology"
    ];

function getInitialState() {
    return {
        applicationList: [],
        phdApplicationList: [],
        fellowshipApplicationList: [],
        MBBSandBDSapplicationList: [],
        MDMSapplicationList: [],
        loading: true,
        StudentRegistrationList: [],
        EDPapplicationList: [],
        MD_MS_MDS_StudentRegistrationList: [],
        DM_MCh_StudentRegistrationList: [],
        AHS_StudentRegistrationList: [],
        BSc_PBBSc_MSc_NPCC_StudentRegistrationList: [],
        BPharm_StudentRegistrationList: [],
        BPT_MPT_StudentRegistrationList: [],
        MSc_Medical_MBA_MPH_StudentRegistrationList: []
    }
}

function Dashboard({ onDataUpdate }) {
    const navigate = useNavigate();
    const [state, setState] = React.useState(getInitialState());
    const [spinner, setspinner] = React.useState(false);

    const goToWebsite = () => {
        window.open('https://sbvu.ac.in/provisional-selection-list/', '_blank');
    };

    const newApplication = () => {
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    async function getApplicationList() {
        try {
            const result = await ApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    applicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetPhdApplicationList() {
        try {
            const result = await getPhdApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    phdApplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const GetMBBSandBDSapplicationList = async () => {
        try {
            const result = await getMBBSandBDSapplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MBBSandBDSapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetFellowshipApplicationList() {
        try {
            const result = await getFellowshipApplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    fellowshipApplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetMDMSApplicationList() {
        try {
            const result = await getMDMSapplicationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MDMSapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function GetStudentRegistrationList() {
        try {
            const result = await getStudentRegistrationListForDashboard({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const getEDPapplicationList = async () => {
        try {
            const result = await getEDPapplicationListForDashboard({ "username": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    EDPapplicationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
        else {
            getApplicationList();
            GetPhdApplicationList();
            GetFellowshipApplicationList();
            GetMBBSandBDSapplicationList();
            GetMDMSApplicationList();
            GetStudentRegistrationList();
            getEDPapplicationList();
            Get_MD_MS_MDS_StudentRegistrationList();
            Get_MD_MCh_StudentRegistrationList();
            Get_AHS_StudentRegistrationList();
            Get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList();
            Get_BPharm_StudentRegistrationList();
            Get_BPT_MPT_StudentRegistrationList();
            Get_MSc_Medical_MBA_MPH_StudentRegistrationList();
        }
        localStorage.removeItem("_id");
        localStorage.removeItem("id");
        localStorage.removeItem("institute");
        localStorage.removeItem("degree");
        localStorage.removeItem("course");
        localStorage.removeItem("paymentStatus");
        localStorage.removeItem("documents");
        localStorage.removeItem("name");
        localStorage.removeItem("applicationNo");
        localStorage.removeItem("regNo");
        localStorage.removeItem("role");
    }, []);

    const Get_MD_MS_MDS_StudentRegistrationList = async () => {
        try {
            const result = await get_MD_MS_MDS_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MD_MS_MDS_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_MD_MCh_StudentRegistrationList = async () => {
        try {
            const result = await get_MD_MCh_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    DM_MCh_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_AHS_StudentRegistrationList = async () => {
        try {
            const result = await get_AHS_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    AHS_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList = async () => {
        try {
            const result = await get_BSc_PBBSc_MSc_NPCC_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BSc_PBBSc_MSc_NPCC_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BPharm_StudentRegistrationList = async () => {
        try {
            const result = await get_BPharm_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BPharm_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_BPT_MPT_StudentRegistrationList = async () => {
        try {
            const result = await get_BPT_MPT_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    BPT_MPT_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Get_MSc_Medical_MBA_MPH_StudentRegistrationList = async () => {
        try {
            const result = await get_MSc_Medical_MBA_MPH_StudentRegistrationList({ "userName": localStorage.getItem("username") });
            if (Array.isArray(result)) {
                setState((prevState) => ({
                    ...prevState,
                    MSc_Medical_MBA_MPH_StudentRegistrationList: result,
                    loading: false
                }));
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const ApplicationEdit = (data) => {
        localStorage.setItem("_id", data._id);
        localStorage.setItem("course", data.course);
        localStorage.setItem("degree", data.degree);
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    const ViewApplication = (data) => {
        localStorage.setItem("_id", data._id);
        onDataUpdate({
            nextPage: 'view-ahs-application'
        });
    };

    const downloadApplicationData = async (data) => {
        try {
            const response = await DownloadApplication(data);
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (err) {
            console.log(err);
        }

    };

    const downloadHallTicket = async (data) => {
        try {
            const course = await getCourseName({ "_id": data._id });
            if (callLetterCourses.indexOf(course.courseName) > -1) {

                const response = await DownloadHallTicket({ "_id": data._id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Call Letter.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
            else {

                const response = await DownloadHallTicket({ "_id": data._id });
                const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Hall Ticket.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await downloadAllDeclarationsInPDF({ "registrationNo": data.registrationNo });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${data.registrationNo}.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    if (state.loading) {
        return (
            <Row style={{ width: '100%', textAlign: 'center' }}>
                <Col>
                    Please wait...
                </Col>
            </Row>
        );
    };

    const phdApplicationEdit = (object) => {
        localStorage.setItem("_id", object._id);
        localStorage.setItem("regNo", object.applicationNo);
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    const fellowshipApplicationEdit = (object) => {
        localStorage.setItem("_id", object._id);
        localStorage.setItem("regNo", object.applicationNo);
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    const MBBSandBDSapplicationEdit = (object) => {
        localStorage.setItem("applicationNo", object.applicationNo);
        localStorage.setItem("institute", object.institute);
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    const MDMSapplicationEdit = (object) => {
        localStorage.setItem("applicationNo", object.applicationNo);
        localStorage.setItem("institute", object.institute);
        onDataUpdate({
            nextPage: 'basic-details'
        });
    };

    const goToPay = (id) => {
        localStorage.setItem('id', id);
        onDataUpdate({
            nextPage: 'payment-details'
        });
    };

    const MBBSandBDSRegistrationEdit = (object) => {
        localStorage.setItem("_id", object._id);
        localStorage.setItem("registrationNo", object.registrationNo);
        navigate("/mbbsandbdsstudentregistration");
    };

    const downloadEDPapplication = async (data) => {
        try {
            setspinner(true);
            const response = await downloadEDPapplicationForm({ 'id': data._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const MD_MS_MDS_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistrationMD_MS_MDS_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const MD_MS_MDS_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/studentregistration_MD_MS_MDS");
    };

    const DM_MCH_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/studentregistration_DM_MCh");
    };

    const AHS_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_AHS");
    };

    const BSc_PBBSc_MSc_NPCC_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BSc_PBBSc_MSc_NPCC_nursing");
    };

    const BPharm_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BPharm");
    };

    const BPT_MPT_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_BPT_MPT");
    };

    const MSc_Medical_MBA_MPH_UploadDocuments = async (data) => {
        localStorage.setItem("id", data._id);
        localStorage.setItem("updateDoc", true);
        navigate("/student_registration_MSc_Medical_MBA_MPH");
    };

    const DM_MCH_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_DM_MCh_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPT_AHS_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_AHS_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BSc_PBBSc_MSc_NPCC_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BSc_PBBSc_MSc_NPCC_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPharm_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BPharm_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const BPT_MPT_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_BPT_MPT_DownloadDeclaration({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            localStorage.removeItem("id");
            navigate("/dashboard");
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const MSc_Medical_MBA_MPH_DownloadApplicationwithDeclaration = async (data) => {
        try {
            setspinner(true);
            const response = await studentRegistration_MSc_Medical_MBA_MPH_downloadAllDeclarations({ "_id": data._id });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
            localStorage.removeItem("id");
            localStorage.removeItem("updateDoc");
            navigate("/dashboard");
        } catch (err) {
            console.log(err);
        }
    };

    const download_PhD_Application = async (data) => {
        setspinner(true);
        try {
            const response = await phdApplicationDownload({ "_id": data._id });
            const href = URL.createObjectURL(new Blob([response], { type: "application/pdf" }));

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', data.applicationNo + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (err) {
            console.log(err);
        }
        setspinner(false);
    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>

            <div>
                <Card style={{ overflowX: 'scroll' }}>
                    <Container>
                        {
                            state.applicationList.length === 0 && state.phdApplicationList.length === 0 &&
                            state.fellowshipApplicationList.length === 0 && state.MDMSapplicationList.length === 0 &&
                            state.MBBSandBDSapplicationList.length === 0 && state.EDPapplicationList.length === 0 &&
                            state.MD_MS_MDS_StudentRegistrationList.length === 0 &&
                            state.DM_MCh_StudentRegistrationList.length === 0 && state.AHS_StudentRegistrationList.length === 0 &&
                            state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.length === 0 && state.BPharm_StudentRegistrationList.length === 0 &&
                            state.BPT_MPT_StudentRegistrationList.length === 0 && state.MSc_Medical_MBA_MPH_StudentRegistrationList.length === 0
                            &&
                            <div>
                                <Row className="justify-content-center" style={{ marginTop: '75px' }}>
                                    <h1 className="text-center font-weight-bold mb-4">Welcome to Sri Balaji Vidyapeeth</h1>
                                    <Button
                                        variant="primary"
                                        className="rounded-pill"
                                        onClick={newApplication}
                                        style={{ width: '200px' }}>
                                        Apply Online
                                    </Button>
                                </Row>
                            </div>
                        }
                        {
                            state.applicationList.length > 0 &&
                            state.applicationList.map((obj, index) => (
                                <Card key={obj._id} className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.course}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card
                                                    onClick={() => ApplicationEdit(obj)}
                                                    className="w-100 p-2 cursor-pointer">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Pencil
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">Edit</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2 cursor-pointer">
                                                    <Row
                                                        onClick={() => ViewApplication(obj)}
                                                        className="align-items-center">
                                                        <Col xs={2}><Icon.Eye
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">View</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                {
                                                    obj.paymentStatus === "Success" &&
                                                    <Card
                                                        onClick={() => downloadApplicationData(obj)}
                                                        className="w-100 p-2 cursor-pointer">
                                                        <Row className="align-items-center">
                                                            <Col xs={2}><Icon.FileEarmarkArrowDown
                                                                size={30}
                                                                style={{ color: "red" }}
                                                            /></Col>
                                                            <Col xs={10}>
                                                                <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                                <p className="text-dark fw-bold m-0 p-0">Download</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                }

                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                {obj.venue &&
                                                    <Card
                                                        onClick={() => downloadHallTicket(obj)}
                                                        className="w-100 p-2 cursor-pointer">
                                                        <Row className="align-items-center">
                                                            <Col xs={2}><Icon.FileEarmarkArrowDown
                                                                size={30}
                                                                style={{ color: "red" }}
                                                            /></Col>
                                                            <Col xs={10}>
                                                                <p className="text-secondary fw-normal m-0 p-0">Hall Ticket / Call Letter</p>
                                                                <p className="text-dark fw-bold m-0 p-0">Download</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))
                        }
                        {
                            state.phdApplicationList.length > 0 &&
                            state.phdApplicationList.map((obj, index) => (
                                <Card className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.intends}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card
                                                    onClick={() => phdApplicationEdit(obj)}
                                                    className="w-100 p-2 cursor-pointer">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Pencil
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">Edit</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                {
                                                    obj.paymentStatus === "Success" &&
                                                    <Card
                                                        onClick={() => download_PhD_Application(obj)}
                                                        className="w-100 p-2 cursor-pointer">
                                                        <Row className="align-items-center">
                                                            <Col xs={2}><Icon.FileEarmarkArrowDown
                                                                size={30}
                                                                style={{ color: "red" }}
                                                            /></Col>
                                                            <Col xs={10}>
                                                                <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                                <p className="text-dark fw-bold m-0 p-0">Download</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                }

                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))
                        }
                        {
                            state.fellowshipApplicationList.length > 0 &&
                            state.fellowshipApplicationList.map((obj, index) => (
                                <Card key={obj._id} className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.fellowshipProgramme}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card
                                                    onClick={() => fellowshipApplicationEdit(obj)}
                                                    className="w-100 p-2 cursor-pointer">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Pencil
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">Edit</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))
                        }
                        {
                            state.MBBSandBDSapplicationList.length > 0 &&
                            state.MBBSandBDSapplicationList.map((obj, index) => (
                                <Card key={obj._id} className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.course}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card
                                                    onClick={() => MBBSandBDSapplicationEdit(obj)}
                                                    className="w-100 p-2 cursor-pointer">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Pencil
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">Edit</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))

                        }
                        {
                            state.MDMSapplicationList.length > 0 &&
                            state.MDMSapplicationList.map((obj, index) => (
                                <Card key={obj._id} className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.course}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card
                                                    onClick={() => MDMSapplicationEdit(obj)}
                                                    className="w-100 p-2 cursor-pointer">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Pencil
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                            <p className="text-dark fw-bold m-0 p-0">Edit</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))

                        }
                        {
                            state.StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for MBBS / BDS</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.registrationNo}</td>
                                                <td>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => MBBSandBDSRegistrationEdit(obj)}
                                                    >Edit
                                                    </Button>
                                                    <Button variant="primary"
                                                        type="button"
                                                        size='sm'
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            state.EDPapplicationList.length > 0 &&
                            state.EDPapplicationList.map((obj, index) => (
                                <Card key={obj._id} className='mt-2 mb-2 card-shadow'>
                                    <Container>
                                        <Row className='mt-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.FileEarmarkText
                                                            size={30}
                                                            style={{ color: "red" }} /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Number</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationNo}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.Mortarboard
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Applied For</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.programme}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CheckCircle
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Verification</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.applicationStatus}</p>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                <Card className="w-100 p-2">
                                                    <Row className="align-items-center">
                                                        <Col xs={2}><Icon.CurrencyRupee
                                                            size={30}
                                                            style={{ color: "red" }}
                                                        /></Col>
                                                        <Col xs={10}>
                                                            <p className="text-secondary fw-normal m-0 p-0">Application Payment</p>
                                                            <p className="text-dark fw-bold m-0 p-0">{obj.paymentStatus}</p></Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 mb-3 text-center g-2'>
                                            <Col sm={12} xs={12} md={3} lg={3} xl={3} className="d-flex">
                                                {
                                                    obj.paymentStatus === "Pending" &&
                                                    <Card
                                                        onClick={() => goToPay(obj._id)}
                                                        className="w-100 p-2 cursor-pointer">
                                                        <Row className="align-items-center">
                                                            <Col xs={2}><Icon.Cash
                                                                size={30}
                                                                style={{ color: "red" }}
                                                            /></Col>
                                                            <Col xs={10}>
                                                                <p className="text-secondary fw-normal m-0 p-0">Application Fee</p>
                                                                <p className="text-dark fw-bold m-0 p-0">Pay</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                }
                                                {
                                                    obj.paymentStatus === "Success" &&
                                                    <Card
                                                        onClick={() => downloadEDPapplication(obj)}
                                                        className="w-100 p-2 cursor-pointer">
                                                        <Row className="align-items-center">
                                                            <Col xs={2}><Icon.FileEarmarkArrowDown
                                                                size={30}
                                                                style={{ color: "red" }}
                                                            /></Col>
                                                            <Col xs={10}>
                                                                <p className="text-secondary fw-normal m-0 p-0">Application</p>
                                                                <p className="text-dark fw-bold m-0 p-0">Download</p>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            ))
                        }

                        {
                            state.MD_MS_MDS_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for MD / MS / MDS</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.MD_MS_MDS_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => MD_MS_MDS_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => MD_MS_MDS_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.DM_MCh_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>DM / MCH Student Registration Application</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.DM_MCh_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => DM_MCH_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => DM_MCH_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.AHS_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for AHS</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.AHS_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPT_AHS_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => AHS_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }


                        {
                            state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for B.Sc / P.B.B.Sc / M.Sc / NPCC</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BSc_PBBSc_MSc_NPCC_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BSc_PBBSc_MSc_NPCC_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BSc_PBBSc_MSc_NPCC_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.BPharm_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for BPharm</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BPharm_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPharm_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BPharm_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.BPT_MPT_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for Biomedical Sciences and BPT / MPT</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.BPT_MPT_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => BPT_MPT_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => BPT_MPT_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }

                        {
                            state.MSc_Medical_MBA_MPH_StudentRegistrationList.length > 0 &&
                            <div>
                                <Row className='mt-3 text-center'>
                                    <Col><strong>Admitted Student Application for M.Sc (Medical) / MBA / MPH / Music & Yoga Therapy.</strong></Col>
                                </Row>
                                <Table striped bordered hover className="mt-3 text-center">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Temporary UIN</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.MSc_Medical_MBA_MPH_StudentRegistrationList.map((obj, index) => (
                                            <tr key={obj._id}>
                                                <td>{index + 1}</td>
                                                <td>{obj.regno}</td>
                                                <td>
                                                    <Icon.Download
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Download Form"
                                                        color="#4073F1"
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => MSc_Medical_MBA_MPH_DownloadApplicationwithDeclaration(obj)}
                                                    >Download
                                                    </Icon.Download>
                                                    <Icon.Upload
                                                        size={25}
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Upload Documents"
                                                        color="#4073F1"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => MSc_Medical_MBA_MPH_UploadDocuments(obj)}
                                                    >Upload Doc
                                                    </Icon.Upload>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Container>
                </Card>
            </div>
        </>
    )
}

export default Dashboard