import React from 'react';
import { Button, Container, Row, Col, Card, Form } from 'react-bootstrap';
import useRazorpay from 'react-razorpay';
import swal from 'sweetalert';
import { ClipLoader } from 'react-spinners';
import {
    getPhdApplicationData,
    createRazorpayOrder,
    phdPaymentSuccess,
    phdPaymentError,
    getPhdApplicationPosition
} from './services';


function PhdPayment({ onDataUpdate }) {
    const [name, setname] = React.useState('');
    const [discipline, setdiscipline] = React.useState('');
    const [spinning, setspinning] = React.useState(false);
    const Razorpay = useRazorpay();

    const role = localStorage.getItem('role');

    async function getApplicationData() {
        const result = await getPhdApplicationData({ applicationNo: localStorage.getItem("regNo") });
        setname(result.data.applicantName);
        setdiscipline(result.data.intends);
    };

    React.useEffect(() => {
        getApplicationData();
    }, []);

    const createOrder = () => {
        const payload = {
            amount: 1000 * 100
        };

        return createRazorpayOrder(payload);
    };

    const onPayClick = async () => {
        try {
            if (role !== 'admin') {

                const response = await getPhdApplicationPosition();
                if (response.applicationPosition === "Open") {
                    setspinning(true);
                    const order = await createOrder();

                    const onSuccessPayment = async (response) => {
                        await phdPaymentSuccess({ response, applicationNo: localStorage.getItem("regNo") });
                        swal({
                            title: "Application Submitted Successfully. Waite for Verification.",
                            icon: 'success'
                        });
                        setspinning(false);
                        onDataUpdate({
                            nextPage: 'dashboard'
                        });
                    };

                    const option = {
                        key: process.env.REACT_APP_RAZORPAY_KEY,
                        amount: 1000 * 100,
                        currency: 'INR',
                        name: 'Sri Balaji Vidyapeeth',
                        order_id: order.id,
                        handler: onSuccessPayment,
                        modal: {
                            ondismiss: function () {
                                setspinning(false);   // Close button event logic
                            }
                        }
                    };

                    const rzp = new Razorpay(option);

                    rzp.on('payment.failed', async (response) => {
                        await phdPaymentError({ response, "applicationNo": localStorage.getItem("regNo") });
                        setspinning(false);
                    });

                    rzp.open();
                }

                if (response.applicationPosition === "Close") {
                    swal({
                        title: "Admission Closed.",
                        icon: 'info'
                    });
                }
            } else {
                setspinning(true);
                const order = await createOrder();

                const onSuccessPayment = async (response) => {
                    await phdPaymentSuccess({ response, applicationNo: localStorage.getItem("regNo") });
                    swal({
                        title: "Application Submitted Successfully. Waite for Verification.",
                        icon: 'success'
                    });
                    onDataUpdate({
                        nextPage: 'dashboard'
                    });
                    setspinning(false);
                };

                const option = {
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: 1000 * 100,
                    currency: 'INR',
                    name: 'Sri Balaji Vidyapeeth',
                    order_id: order.id,
                    handler: onSuccessPayment,
                    modal: {
                        ondismiss: function () {
                            setspinning(false);   // Close button event logic
                        }
                    }
                };

                const rzp = new Razorpay(option);

                rzp.on('payment.failed', async (response) => {
                    await phdPaymentError({ response, "applicationNo": localStorage.getItem("regNo") });
                    setspinning(false);
                });

                rzp.open();

            }
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "60%", padding: 16 }}>
                        <Form>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}><strong>Name:</strong></Col>
                                <Col xs={12} sm={8}>{name}</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}><strong>Discipline:</strong></Col>
                                <Col xs={12} sm={8}>{discipline}</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}><strong>Application Fees:</strong></Col>
                                <Col xs={12} sm={8}>1000</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='text-center'>
                                    {spinning === false &&
                                        <Button
                                            variant="primary"
                                            type="button"
                                            style={{ width: '70px' }}
                                            onClick={onPayClick}
                                        >
                                            Pay
                                        </Button>
                                    }
                                    <ClipLoader
                                        color="#36d7b7"
                                        loading={spinning}
                                        size={40}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default PhdPayment