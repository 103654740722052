import React from 'react';
import { Button, Row, Col, Card, Form } from 'react-bootstrap';
import Spinner from './Spinner';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import {
    MDMS_Documents_Upload,
    get_MDMS_uploaded_documents,
    deleteMDMSdocuments,
    getMDMSuploadedIdProofName
} from './services';

function MDMSdocumentsUpload({ onDataUpdate }) {
    const navigate = useNavigate();
    const [spinner, setspinner] = React.useState(false);
    const [file, Setfile] = React.useState();
    const [aadharNo, SetaadharfNo] = React.useState('');
    const [voteridNo, SetvoteridNo] = React.useState('');
    const [panNo, SetpanNo] = React.useState('');
    const [docname, Setdocname] = React.useState('');
    const [uploadedDoc, SetUploadedDoc] = React.useState([]);
    const fileInputRef1 = React.useRef(null);
    const fileInputRef2 = React.useRef(null);
    const fileInputRef3 = React.useRef(null);
    const fileInputRef4 = React.useRef(null);
    const fileInputRef5 = React.useRef(null);
    const fileInputRef6 = React.useRef(null);
    const fileInputRef7 = React.useRef(null);
    const fileInputRef8 = React.useRef(null);
    const fileInputRef9 = React.useRef(null);
    const fileInputRef10 = React.useRef(null);
    const fileInputRef11 = React.useRef(null);
    const fileInputRef12 = React.useRef(null);
    const fileInputRef13 = React.useRef(null);
    const fileInputRef14 = React.useRef(null);
    const fileInputRef15 = React.useRef(null);
    const requiredDoc = [
        "photo",
        "12th Marksheet",
        "10th Marksheet",
        "Birth Certificate",
        "Online Provisional Allotment Letter issued by MCC",
        "PG NEET Admit Card issued by NBE",
        "PG NEET Result / Rank letter issued by NBE",
        "All mark sheets of MBBS / BDS",
        "Internship Completion Certificate",
        "MBBS / BDS Permanent registration certificate issued by MCI / NMC or DCI / State Medical / Dental Council of India",
        "MBBS / BDS Degree Certificate"
    ];

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    }, []);

    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    const UploadPhoto = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "photo");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef1.current) {
                    fileInputRef1.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Upload12thMarksheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "12th Marksheet");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef2.current) {
                    fileInputRef2.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Upload10thMarksheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "10th Marksheet");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef3.current) {
                    fileInputRef3.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadBirthCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Birth Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef10.current) {
                    fileInputRef10.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadTransfer_Migration_Conduct_Certificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Transfer / Migration / Conduct Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef8.current) {
                    fileInputRef8.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadCasteCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Caste Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef5.current) {
                    fileInputRef5.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadPhysicallyChallengeCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Physically Handicapped Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef4.current) {
                    fileInputRef4.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadProvisionalLetter = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Online Provisional Allotment Letter issued by MCC");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef6.current) {
                    fileInputRef6.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadNeetAdmitCard = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "PG NEET Admit Card issued by NBE");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef7.current) {
                    fileInputRef7.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadNeet_result_rank_letter = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "PG NEET Result / Rank letter issued by NBE");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef9.current) {
                    fileInputRef9.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Uploadmbbsbdsmarksheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "All mark sheets of MBBS / BDS");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef12.current) {
                    fileInputRef12.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadInternshipCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Internship Completion Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef13.current) {
                    fileInputRef13.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadMBBS_BDS_RegistrationCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "MBBS / BDS Permanent registration certificate issued by MCI / NMC or DCI / State Medical / Dental Council of India");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef14.current) {
                    fileInputRef14.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadMBBS_BDS_DegreeCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "MBBS / BDS Degree Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MDMS_Documents_Upload(formData);
                getUploadedDocuments();
                if (fileInputRef15.current) {
                    fileInputRef15.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleUpload = async () => {
        try {
            if (file) {
                if (aadharNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Aadhar Card");
                    formData.append('idProofNo', aadharNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MDMS_Documents_Upload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
                if (voteridNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Voter Id");
                    formData.append('idProofNo', voteridNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MDMS_Documents_Upload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
                if (panNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Pan Card No");
                    formData.append('idProofNo', panNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MDMS_Documents_Upload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function getUploadedDocuments() {
        const doc = await get_MDMS_uploaded_documents({ applicationNo: localStorage.getItem('applicationNo') });
        SetUploadedDoc(doc.uploadDocuments);
    };

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deleteMDMSdocuments({ filedata, applicationNo: localStorage.getItem("applicationNo") });
            getUploadedDocuments();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const SaveandNext = async () => {
        try {
            const uploadedDocNames = [];
            uploadedDoc.forEach((object) => {
                uploadedDocNames.push(object.documentName);
            });
            const IdProofName = await getMDMSuploadedIdProofName({ applicationNo: localStorage.getItem('applicationNo') });
            const areAllDocsUploaded = requiredDoc.every(doc => uploadedDocNames.includes(doc));
            if (areAllDocsUploaded) {
                if (IdProofName.result.idProofName === "Aadhar Card" || IdProofName.result.idProofName === "Voter Id" || IdProofName.result.idProofName === "Pan Card No") {
                    onDataUpdate({
                        nextPage: 'payment-details'
                    });
                }
                else {
                    swal({
                        title: "Please Upload All Required Documents.",
                        icon: 'info'
                    });
                }
            }
            else {
                swal({
                    title: "Please Upload All Required Documents.",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }

    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Card className='p-3 mb-4'>
                <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '5px' }}>
                    MDMS Admission</h3>
                <Form>
                    <Row className="mt-2">
                        <Col><strong>Documents Upload</strong></Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col style={{ color: "red" }}>
                            Note: Photo should be in jpg / jpeg / png format & Documents should be in pdf format.
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Photo<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef1}
                            />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadPhoto} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>12th Marksheet<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef2} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={Upload12thMarksheet} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>10th Marksheet<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef3} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={Upload10thMarksheet}
                            />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Birth Certificate<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef10} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadBirthCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Transfer / Migration / Conduct Certificate</div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef8} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadTransfer_Migration_Conduct_Certificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Caste Certificate</div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef5} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadCasteCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Physically Handicapped Certificate</div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef4} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadPhysicallyChallengeCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Online Provisional Allotment Letter issued by MCC<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef6} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadProvisionalLetter} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>PG NEET Admit Card issued by NBE<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef7} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadNeetAdmitCard} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>PG NEET Result / Rank letter issued by NBE<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef9} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadNeet_result_rank_letter} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>All mark sheets of MBBS / BDS<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef12} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={Uploadmbbsbdsmarksheet} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Internship Completion Certificate<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef13} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadInternshipCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>MBBS / BDS Permanent registration certificate issued by MCI / NMC or DCI / State Medical / Dental Council of India<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef14} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadMBBS_BDS_RegistrationCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>MBBS / BDS Degree Certificate<span style={{ color: "red" }}>*</span></div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef15} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={UploadMBBS_BDS_DegreeCertificate} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <div>Photo Identity Proof<span style={{ color: "red" }}>*</span></div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={4}>
                            <Form.Select onChange={docnameChange} name='documentName'>
                                <option>Select Photo Identity Proof</option>
                                <option value="Aadhar Card">Aadhar Card</option>
                                <option value="Voter ID">Voter ID</option>
                                <option value="Pan Card">Pan Card</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} sm={4}>
                            <input type="file"
                                accept=".jpg,.jpeg,.pdf,.png"
                                onChange={handleFileInputChange}
                                ref={fileInputRef11} />
                        </Col>
                        <Col xs={12} sm={4}>
                            <input
                                type="button"
                                value="Upload"
                                onClick={handleUpload}
                                disabled={!aadharNo && !voteridNo && !panNo} />
                        </Col>
                    </Row>
                    {
                        docname === "Aadhar Card" &&
                        <Row className="mt-3">
                            <Col xs={12} sm={6}>Aadhar Card No</Col>
                            <Col xs={12} sm={6}>
                                <Form.Control
                                    type='text'
                                    name='Aadhar Card No'
                                    value={aadharNo}
                                    onChange={(e) => SetaadharfNo(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Row>
                    }
                    {
                        docname === "Voter ID" &&
                        <Row className="mt-3">
                            <Col xs={12} sm={6}>Voter Id No</Col>
                            <Col xs={12} sm={6}>
                                <Form.Control
                                    type='text'
                                    name='Voter Id No'
                                    value={voteridNo}
                                    onChange={(e) => SetvoteridNo(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Row>
                    }
                    {
                        docname === "Pan Card" &&
                        <Row className="mt-3">
                            <Col xs={12} sm={6}>Pan Card No</Col>
                            <Col xs={12} sm={6}>
                                <Form.Control
                                    type='text'
                                    name='Pan Card No'
                                    value={panNo}
                                    onChange={(e) => SetpanNo(e.target.value)}
                                >
                                </Form.Control>
                            </Col>
                        </Row>
                    }
                    <Row className='mt-3'>
                        <Col style={{ color: "red", marginLeft: "20px" }}>
                            <Row>
                                Note : Kindly bring the following at the time of  admission.
                            </Row>
                            <Row>
                                * Photo Identity Proof (Aadhar Card, Voter Id, Passport).
                            </Row>
                            <Row>
                                * PAN Card.
                            </Row>
                            <Row>
                                * 10 Passport size photographs of the candidate as in the PG NEET Admit Card.
                            </Row>
                            <Row>
                                * Photocopies of all the above certificates (2 sets, self attested).
                            </Row>
                            <Row>
                                * One Photograph of student with Parent / Guardian.
                            </Row>
                        </Col>
                    </Row>
                    {
                        uploadedDoc.map((data, index) => (
                            <Row className="mt-3" key={data._id}>
                                <Col xs={6} sm={3}>{index + 1}</Col>
                                <Col xs={6} sm={3}>{data.documentName}</Col>
                                <Col xs={6} sm={3}>
                                    <input
                                        type="button"
                                        value="view"
                                        style={{ marginRight: '10px', marginLeft: '100px' }}
                                        onClick={() => viewfile(data)}
                                    />
                                </Col>
                                <Col xs={6} sm={3}>
                                    <input
                                        type="button"
                                        value="delete"
                                        onClick={() => deletefile(data)}
                                    />
                                </Col>
                            </Row>
                        ))
                    }
                    <Row className='mt-3'>
                        <Col style={{ textAlign: "center" }}>
                            <Button
                                variant="primary"
                                type="button"
                                style={{ alignItems: 'center', width: '150px' }}
                                className="w-70"
                                onClick={SaveandNext}
                            >
                                Save & Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default MDMSdocumentsUpload