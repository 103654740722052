import React from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import {
    MBBS_BDS_DocumentsUpload,
    get_MBBS_uploaded_documents,
    deleteMBBSDoc,
    getUploadedIdProofName
} from './services';
import Spinner from './Spinner';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";

function MBBSandBDSdocumentsUpload({ onDataUpdate }) {
    const [spinner, setspinner] = React.useState(false);
    const [file, Setfile] = React.useState();
    const [docname, Setdocname] = React.useState('');
    const [uploadedDoc, SetUploadedDoc] = React.useState([]);
    const [aadharNo, SetaadharfNo] = React.useState('');
    const [voteridNo, SetvoteridNo] = React.useState('');
    const [panNo, SetpanNo] = React.useState('');
    const navigate = useNavigate();
    const fileInputRef1 = React.useRef(null);
    const fileInputRef2 = React.useRef(null);
    const fileInputRef3 = React.useRef(null);
    const fileInputRef4 = React.useRef(null);
    const fileInputRef5 = React.useRef(null);
    const fileInputRef6 = React.useRef(null);
    const fileInputRef7 = React.useRef(null);
    const fileInputRef8 = React.useRef(null);
    const fileInputRef9 = React.useRef(null);
    const fileInputRef10 = React.useRef(null);
    const fileInputRef11 = React.useRef(null);
    const requiredDoc = [
        "photo",
        "12th Marksheet",
        "10th Marksheet",
        "Provisional Allotment Letter issued by MCC, DGHS",
        "NEET Admit Card issued by NTA",
        "NEET Rank Letter issued by NTA",
        "Birth Certificate"
    ];

    const handleFileInputChange = (event) => {
        const { target: { files } } = event;
        if (!files[0]) {
            return;
        }

        Setfile(files[0]);
    };

    const UploadPhoto = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "photo");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef1.current) {
                    fileInputRef1.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Upload12thMarksheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "12th Marksheet");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef2.current) {
                    fileInputRef2.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const Upload10thMarksheet = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "10th Marksheet");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef3.current) {
                    fileInputRef3.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadProvisionalAllotment = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Provisional Allotment Letter issued by MCC, DGHS");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef4.current) {
                    fileInputRef4.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadCasteCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Caste Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef5.current) {
                    fileInputRef5.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadNEETAdmitCard = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "NEET Admit Card issued by NTA");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef6.current) {
                    fileInputRef6.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadNEETRankLetter = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "NEET Rank Letter issued by NTA");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef7.current) {
                    fileInputRef7.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadTransfer_Migration_Conduct_Certificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Transfer / Migration / Conduct Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef8.current) {
                    fileInputRef8.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadPHCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "PH Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef9.current) {
                    fileInputRef9.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const UploadBirthCertificate = async () => {
        try {
            if (file) {
                setspinner(true);
                const formData = new FormData();
                formData.append('document_name', "Birth Certificate");
                formData.append('file', file);
                formData.append('applicationNo', localStorage.getItem('applicationNo'));
                await MBBS_BDS_DocumentsUpload(formData);
                getUploadedDocuments();
                if (fileInputRef10.current) {
                    fileInputRef10.current.value = '';
                }
                setspinner(false);
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const handleUpload = async () => {
        try {
            if (file) {
                if (aadharNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Aadhar Card");
                    formData.append('idProofNo', aadharNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MBBS_BDS_DocumentsUpload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
                if (voteridNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Voter Id");
                    formData.append('idProofNo', voteridNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MBBS_BDS_DocumentsUpload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
                if (panNo) {
                    setspinner(true);
                    const formData = new FormData();
                    formData.append('idProofName', "Pan Card No");
                    formData.append('idProofNo', panNo);
                    formData.append('document_name', docname);
                    formData.append('file', file);
                    formData.append('applicationNo', localStorage.getItem('applicationNo'));
                    await MBBS_BDS_DocumentsUpload(formData);
                    getUploadedDocuments();
                    if (fileInputRef11.current) {
                        fileInputRef11.current.value = '';
                    }
                    setspinner(false);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    async function getUploadedDocuments() {
        const doc = await get_MBBS_uploaded_documents({ applicationNo: localStorage.getItem('applicationNo') });
        SetUploadedDoc(doc.uploadDocuments);
    };

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const deletefile = async (filedata) => {
        try {
            setspinner(true);
            await deleteMBBSDoc({ filedata, applicationNo: localStorage.getItem("applicationNo") });
            getUploadedDocuments();
            setspinner(false);
        }
        catch (err) {
            console.log(err);
        }
    };

    const viewfile = (filedata) => {
        if (filedata.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + filedata.filePath, "_blank");
        }
        else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const SaveandNext = async () => {
        try {
            const uploadedDocNames = [];
            uploadedDoc.forEach((object) => {
                uploadedDocNames.push(object.documentName);
            });
            const IdProofName = await getUploadedIdProofName({ applicationNo: localStorage.getItem('applicationNo') });
            const areAllDocsUploaded = requiredDoc.every(doc => uploadedDocNames.includes(doc));
            if (areAllDocsUploaded) {
                if (IdProofName.result.idProofName === "Aadhar Card" || IdProofName.result.idProofName === "Voter Id" || IdProofName.result.idProofName === "Pan Card No") {
                    onDataUpdate({
                        nextPage: 'payment-details'
                    });
                }
                else {
                    swal({
                        title: "Please Upload All Required Documents.",
                        icon: 'info'
                    });
                }
            }
            else {
                swal({
                    title: "Please Upload All Required Documents.",
                    icon: 'info'
                });
            }
        }
        catch (err) {
            console.log(err);
        }

    };

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center" }} className='mt-4 mb-5' >
                    <Card style={{ width: "100%", padding: 16 }}>
                        <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '10px' }}>
                            MBBS / BDS Admission</h3>
                        <Form>
                            <Row className="mt-3">
                                <Col><strong>Documents Upload</strong></Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col style={{ color: "red" }}>
                                    Note: Photo should be in jpg / jpeg / png format & Documents should be in pdf format.
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Photo<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef1}
                                    />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadPhoto} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>12th Marksheet<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef2} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={Upload12thMarksheet} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>10th Marksheet<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef3} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={Upload10thMarksheet}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Provisional Allotment Letter issued by MCC, DGHS<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef4} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadProvisionalAllotment} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Caste Certificate</div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef5} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadCasteCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>NEET Admit Card issued by NTA<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef6} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadNEETAdmitCard} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>NEET Rank Letter issued by NTA<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef7} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadNEETRankLetter} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Transfer / Migration / Conduct Certificate</div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef8} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadTransfer_Migration_Conduct_Certificate} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>PH Certificate</div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef9} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadPHCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Birth Certificate<span style={{ color: "red" }}>*</span></div>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef10} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={UploadBirthCertificate} />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <div>Photo Identity Proof<span style={{ color: "red" }}>*</span></div>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={4}>
                                    <Form.Select onChange={docnameChange} name='documentName'>
                                        <option>Select Photo Identity Proof</option>
                                        <option value="Aadhar Card">Aadhar Card</option>
                                        <option value="Voter ID">Voter ID</option>
                                        <option value="Pan Card">Pan Card</option>
                                    </Form.Select>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input type="file"
                                        accept=".jpg,.jpeg,.pdf,.png"
                                        onChange={handleFileInputChange}
                                        ref={fileInputRef11} />
                                </Col>
                                <Col xs={12} sm={4}>
                                    <input
                                        type="button"
                                        value="Upload"
                                        onClick={handleUpload}
                                        disabled={!aadharNo && !voteridNo && !panNo} />
                                </Col>
                            </Row>
                            {
                                docname === "Aadhar Card" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Aadhar Card No</Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='Aadhar Card No'
                                            value={aadharNo}
                                            onChange={(e) => SetaadharfNo(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {
                                docname === "Voter ID" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Voter Id No</Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='Voter Id No'
                                            value={voteridNo}
                                            onChange={(e) => SetvoteridNo(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            {
                                docname === "Pan Card" &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Pan Card No</Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='Pan Card No'
                                            value={panNo}
                                            onChange={(e) => SetpanNo(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-3'>
                                <Col style={{ color: "red", marginLeft: "20px" }}>
                                    <Row>
                                        Note : Kindly bring the following at the time of  admission.
                                    </Row>
                                    <Row>
                                        * 10 Passport size photographs of the candidate as in the UG NEET Admit Card.
                                    </Row>
                                    <Row>
                                        * Photocopies of all the above certificates (2 sets, self attested).
                                    </Row>
                                    <Row>
                                        * Family Photo (with parents).
                                    </Row>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <strong >Uploaded Documents</strong>
                            </Row>
                            {uploadedDoc && uploadedDoc.length > 0 &&
                                uploadedDoc.map((data, index) => (
                                    <Row className="mt-3" key={data._id}>
                                        <Col xs={6} sm={3}>{index + 1}</Col>
                                        <Col xs={6} sm={3}>{data.documentName}</Col>
                                        <Col xs={6} sm={3}>
                                            <input
                                                type="button"
                                                value="view"
                                                style={{ marginRight: '10px', marginLeft: '100px' }}
                                                onClick={() => viewfile(data)}
                                            />
                                        </Col>
                                        <Col xs={6} sm={3}>
                                            <input
                                                type="button"
                                                value="delete"
                                                onClick={() => deletefile(data)}
                                            />
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row className='mt-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <Button
                                        variant="primary"
                                        type="button"
                                        style={{ alignItems: 'center', width: '150px' }}
                                        className="w-70"
                                        onClick={SaveandNext}
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Row>
            </Container>
        </>
    )
}

export default MBBSandBDSdocumentsUpload