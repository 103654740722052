import React from 'react';
import { Button, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import {
    saveMBBS_BDS_Application,
    MBBS_application_count_MGMCRI,
    MBBS_application_count_SSSMCRI,
    MBBS_application_count_IGIDS,
    getMBBSandBDSapplicationDetailsByApplicationNo,
    updateMBBSandBDSapplication
} from './services';
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
function getInitialState() {
    return {
        applicantName: '',
        institute: '',
        mobileNo: '',
        mailid: '',
        gender: '',
        dateofbirth: '',
        parentName: '',
        parentmailid: '',
        parentmobileNo: '',
        address: '',
        course: '',
        NEETRollNo: '',
        NEETRank: '',
        NEETMark: '',
        submit: false,
        edit: false
    }
};

function MBBSandBDSapplication({ onDataUpdate }) {
    const [state, setState] = React.useState(getInitialState());
    const navigate = useNavigate();
    const regiNoBegin = 1000;
    const IGIDSregnobegin = 2000;

    React.useEffect(() => {
        var user = localStorage.getItem('username');
        if (!user) {
            swal({
                title: "You are unauthorized, Please login.",
                icon: 'error',
            })
            navigate('/');
        }
    }, []);

    const onChangeInput = (event) => {
        const { target: { name, value, checked } } = event;
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const SaveandNext = async () => {
        if (state.edit === false) {
            if (state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI") {
                let count = await MBBS_application_count_MGMCRI();
                setState((prevState) => ({
                    ...prevState,
                    userName: localStorage.getItem("username"),
                    paymentStatus: "Pending",
                    applicationStatus: "Pending",
                    applicationNo: `T23M0${regiNoBegin + count.result + 1}`,
                    submit: true
                }));
            }
            if (state.institute === "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
                let count = await MBBS_application_count_SSSMCRI();
                setState((prevState) => ({
                    ...prevState,
                    userName: localStorage.getItem("username"),
                    paymentStatus: "Pending",
                    applicationStatus: "Pending",
                    applicationNo: `T23S0${regiNoBegin + count.result + 1}`,
                    submit: true
                }));
            }
            if (state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
                let count = await MBBS_application_count_IGIDS();
                setState((prevState) => ({
                    ...prevState,
                    userName: localStorage.getItem("username"),
                    paymentStatus: "Pending",
                    applicationStatus: "Pending",
                    applicationNo: `T23I0${IGIDSregnobegin + count.result + 1}`,
                    submit: true
                }));
            }
        }
        if (state.edit === true) {
            update();
        }
    };

    const update = async () => {
        try {
            const result = await updateMBBSandBDSapplication(state);
            setState((prevState) => ({
                ...prevState,
                edit: false
            }));
            onDataUpdate({
                nextPage: 'documents-upload'
            });
        }
        catch (err) {
            console.log(err);
        }
    };

    async function save() {
        try {
            await saveMBBS_BDS_Application(state);
            localStorage.setItem("applicationNo", state.applicationNo);
            localStorage.setItem("institute", state.institute);
            setState((prevState) => ({
                ...prevState,
                submit: false
            }));
            onDataUpdate({
                nextPage: 'documents-upload'
            });
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        if (state.submit) {
            save();
        }
    }, [state.submit]);

    React.useEffect(() => {
        if (state.institute) {
            if (state.institute === "Mahatma Gandhi Medical College & Research Institute - MGMCRI" || "Shri Sathya Sai Medical College & Research Institute - SSSMCRI") {
                setState((prevState) => ({
                    ...prevState,
                    course: "MBBS"
                }));
            }
            if (state.institute === "Indira Gandhi Institute Of Dental Sciences - IGIDS") {
                setState((prevState) => ({
                    ...prevState,
                    course: "BDS"
                }));
            }
        }
    }, [state.institute]);

    React.useEffect(() => {
        const ApplicationNo = localStorage.getItem("applicationNo");
        if (ApplicationNo) {
            getApplicationDetailsByApplicationNo(ApplicationNo);
        }
    }, []);

    const getApplicationDetailsByApplicationNo = async (ApplicationNo) => {
        try {
            const result = await getMBBSandBDSapplicationDetailsByApplicationNo({ "applicationNo": ApplicationNo });
            setState(result);
            setState((prevState) => ({
                ...prevState,
                edit: true
            }))
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <Card className="p-3 pb-5">
                <Form>
                    <Row style={{ textAlign: "center", color: '#fd7e14' }}>
                        <Col><h4>MBBS / BDS Admission</h4></Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} sm={6}>Name of the Applicant<br />
                            <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(in H.S.C. Mark Sheet)</span>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='applicantName'
                                value={state.applicantName}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    {
                        state.edit === false &&
                        <div>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Select Institute</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select onChange={onChangeInput} name='institute' value={state.institute}>
                                        <option>Select Institute</option>
                                        <option name="institute"
                                            value="Mahatma Gandhi Medical College & Research Institute - MGMCRI">
                                            Mahatma Gandhi Medical College & Research Institute - MGMCRI</option>
                                        <option name="institute"
                                            value="Shri Sathya Sai Medical College & Research Institute - SSSMCRI">
                                            Shri Sathya Sai Medical College & Research Institute - SSSMCRI
                                        </option>
                                        <option name="institute"
                                            value="Indira Gandhi Institute Of Dental Sciences - IGIDS">
                                            Indira Gandhi Institute Of Dental Sciences - IGIDS
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={12} sm={6}>Course</Col>
                                <Col xs={12} sm={6}>
                                    {state.course}
                                </Col>
                            </Row>
                        </div>
                    }
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>NEET Roll No</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='NEETRollNo'
                                value={state.NEETRollNo}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>NEET Rank</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='NEETRank'
                                value={state.NEETRank}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>NEET Mark</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='NEETMark'
                                value={state.NEETMark}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Mobile No</Col>
                        <Col xs={12} sm={6}>
                            <InputGroup>
                                <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                <Form.Control
                                    name='mobileNo'
                                    value={state.mobileNo}
                                    type="tel"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    maxLength="10"
                                    onChange={onChangeInput}
                                >
                                </Form.Control>
                            </InputGroup>

                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Mail Id</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='email'
                                name='mailid'
                                value={state.mailid}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Gender</Col>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Check
                                    inline
                                    label="Male"
                                    type="radio"
                                    id="option-1"
                                    name="gender"
                                    value="Male"
                                    checked={state.gender === 'Male'}
                                    onChange={onChangeInput}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="Female"
                                    type="radio"
                                    id="option-2"
                                    name="gender"
                                    value="Female"
                                    checked={state.gender === 'Female'}
                                    onChange={onChangeInput}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="Third Gender"
                                    type="radio"
                                    id="option-2"
                                    name="gender"
                                    value="Third Gender"
                                    checked={state.gender === 'Third Gender'}
                                    onChange={onChangeInput}
                                >
                                </Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Date of Birth</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='Date'
                                name='dateofbirth'
                                value={state.dateofbirth}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Name of Parent / Guardian</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='parentName'
                                value={state.parentName}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Parent Mail Id</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='email'
                                name='parentmailid'
                                value={state.parentmailid}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Parent Mobile No</Col>
                        <Col xs={12} sm={6}>
                            <InputGroup>
                                <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                <Form.Control
                                    name='parentmobileNo'
                                    value={state.parentmobileNo}
                                    type="tel"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    maxLength="10"
                                    onChange={onChangeInput}
                                >
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} sm={6}>Address</Col>
                        <Col xs={12} sm={6}>
                            <Form.Control
                                type='text'
                                name='address'
                                as="textarea"
                                value={state.address}
                                onChange={onChangeInput}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col style={{ textAlign: "center" }}>
                            <Button
                                variant="primary"
                                type="button"
                                style={{ alignItems: 'center', width: '150px' }}
                                className="w-70"
                                onClick={SaveandNext}
                            >
                                Save & Next
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default MBBSandBDSapplication